import { Component, OnInit } from '@angular/core';
import { ProviderName, ExternalProviderService, ExternalConnect, LoggingService, TMAuthService } from '@fgb/core';

@Component({
  selector: 'fgb-manage-tickets',
  templateUrl: './manage-tickets.component.html',
  styleUrls: ['./manage-tickets.component.scss'],
})
export class ManageTicketsComponent implements OnInit {
  jwt: string | null;
  providerType = ProviderName.TicketMaster;
  constructor(
    private externalProviderService: ExternalProviderService,
    private loggingService: LoggingService,
    private tmOauthService: TMAuthService
  ) {}

  ngOnInit() {
    this.jwt = localStorage.getItem('token');
  }

  async connectToTM() {
    this.tmOauthService.ssoToModernAccountRedirect();
  }

  async externalRedirectToProvider() {
    if (this.jwt) {
      this.externalProviderService.externalRedirectToProvider(new ExternalConnect(this.jwt)).catch(() => {
        // Interceptor will have caught the unauthorised response here and refreshed the token so we can retrieve and try again
        this.jwt = localStorage.getItem('token');
        if (this.jwt) {
          this.externalProviderService.externalRedirectToProvider(new ExternalConnect(this.jwt));
        }
      });
    } else {
      this.loggingService.error('Unable to retrieve JWT');
    }
  }
}
