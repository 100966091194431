import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Company, CompanyOfferQuery, CompanyQuery, CompanyOfferModel, ScorecardService, CompanyService, CompanyOfferService } from '@fgb/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { firstValueFrom, Observable } from 'rxjs';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';

@Component({
  selector: 'fgb-offers-page',
  templateUrl: './offers-page.component.html',
  styleUrls: ['./offers-page.component.scss'],
})
export class OffersPageComponent implements OnInit {
  featuredOffers$: Observable<CompanyOfferModel[]>;
  featuredPartners$: Observable<Company[]>;

  customOptions: OwlOptions = {
    autoWidth: true,
    margin: OwlCarouselSettings.Margin,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: { items: 1 },
      540: { items: 2 },
      740: { items: 3 },
    },
  };

  customOptions2: OwlOptions = {
    autoWidth: true,
    margin: OwlCarouselSettings.Margin,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: { items: 2 },
      540: { items: 4 },
      740: { items: 6 },
    },
  };

  constructor(
    private companyQuery: CompanyQuery,
    private companyOfferService: CompanyOfferService,
    private companyOfferQuery: CompanyOfferQuery,
    private router: Router,
    private scorecardService: ScorecardService,
    private companyService:CompanyService
  ) {}

  ngOnInit() {
    firstValueFrom(this.scorecardService.fetchScorecards());
    firstValueFrom(this.companyOfferService.fetchOffers(0, 0)).then(() => firstValueFrom(
    this.companyService.fetchAllCompanies())).then(() => {
      // get query
      this.featuredPartners$ = this.companyQuery.selectFavouriteCompanies();
      this.featuredOffers$ = this.companyOfferQuery.selectFeaturedOffers();
    });
  }

  navigateToOffer(offerId: number) {
    this.router.navigateByUrl(`offers/details/${offerId}`);
  }
}
