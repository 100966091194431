import { Directive, Input, TemplateRef, ViewContainerRef, OnDestroy, HostListener } from '@angular/core';
import { PopulationQuery } from '@fgb/core';
import { Subscription } from 'rxjs';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[inPopulation]',
})
export class InPopulationDirective implements OnDestroy {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private populationQuery: PopulationQuery
  ) {}

  private popSub: Subscription;

  @Input() set inPopulation(populations: string[]) {
    this.popSub = this.populationQuery.selectCurrentPopulation().subscribe((p) => {
      this.viewContainer.clear();
      if (p && populations.includes(p.DisplayName)) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    });
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.popSub) {
      this.popSub.unsubscribe();
    }
  }
}
