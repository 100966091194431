<form [formGroup]="acceptForm" class="row" (ngSubmit)="submit()">
  <div class="col-lg-3">
    <label class="ps-2">Partner Code</label>
    <input formControlName="code" placeholder="Insert Code Here" class="form-control bg-muted border-0 ps-2" />
  </div>
  <div class="offset-lg-1 col-lg-3">
    <label class="ps-2 mt-4 mt-lg-0">Partner Secret Code</label>
    <input formControlName="secretCode" placeholder="Insert Code Here" class="form-control bg-muted border-0 ps-2" />
  </div>
  <div class="col-10 col-lg-4 d-flex align-items-end mt-4 mt-lg-0">
    <button
      type="submit"
      [disabled]="acceptForm.invalid"
      class="btn btn-block btn-success flex-grow-0 fw-bold me-0 ms-auto"
    >
      Add New Partner
    </button>
  </div>
  <div class="col-lg-1 col-2 d-flex align-items-center justify-content-center mt-4">
    <div (click)="close.emit()" class="icon icon-cancel-circle text-primary"></div>
  </div>
</form>
