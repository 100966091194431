import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fgb-marketplace-page',
  templateUrl: './marketplace-page.component.html',
  styleUrls: ['./marketplace-page.component.scss']
})
export class MarketplacePageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
