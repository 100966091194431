<nav class="nav nav-secondary badges-nav">
  <!--Account-->
  <a class="nav-item" [routerLink]="['/offers']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
    <div class="icon material-icons" aria-hidden="true">loyalty</div>
    <label>{{ 'badges.nav.offer' | translate }}</label>
  </a>
  <a class="nav-item" [routerLink]="['/badges']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
    <div class="icon material-icons" aria-hidden="true">local_police</div>
    <label>{{ 'badges.nav.badge' | translate }}</label>
  </a>
</nav>
