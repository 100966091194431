import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VouchersComponent } from './components/vouchers/vouchers.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LocationVoucherComponent } from './components/location-voucher/location-voucher.component';

@NgModule({
  declarations: [VouchersComponent, LocationVoucherComponent],
  exports: [VouchersComponent, LocationVoucherComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    NgbModule
  ]
})
export class VouchersModule { }
