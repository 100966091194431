<div
  ngbDropdown
  class="notification-button"
  [class.open]="isOpen"
  (openChange)="toggleNotificationCenter($event)"
  #notificationDropdown="ngbDropdown"
>
  <button
    class="notification-btn btn px-0 border-0"
    id="notificationDropdown"
    attr.aria-label="{{ 'nav.notification.screenreader' | translate }}"
    tabindex="-1"
    ngbDropdownToggle
  >
    <span class="icon notification-bell material-icons" aria-hidden="true" [style.font-size]="size + 'px'">notifications </span>
    @if(unseenCount()){
    <span class="notification-badge">
      {{ unseenCount() }}
    </span>
    }
  </button>

  <div ngbDropdownMenu aria-labelledby="notificationDropdown" class="notification-dropdown shadow dropdown-menu border-0 p-0">
    <div class="notification-header text-black d-flex mx-3 font-weight-bold">
      <div class="notification-title fs-6">
        {{ 'navbar.notifications.title' | translate : { fgbdefault: 'Notifications' } }}
      </div>
      <i class="material-icons material-icons-outlined close-btn" (click)="notificationDropdown.close()">cancel</i>
    </div>
    <fgbcl-notification-hub />
  </div>
  <div class="notification-mobile-backdrop"></div>
</div>
