import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { LoggingService, NotificationCenterService, SignalRHub } from '@fgb/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'fgb-master-page',
  templateUrl: './master-page.component.html',
  styleUrls: ['./master-page.component.scss'],
})
export class MasterPageComponent implements OnInit {
  private _destroyRef = inject(DestroyRef);
  private signalRHub = inject(SignalRHub);
  private logService = inject(LoggingService);
  private notificationCenterService = inject(NotificationCenterService);
  constructor() {}

  ngOnInit() {
    this.signalRHub.connectionEstablished$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((connection) => {
      this.logService.debug('SignalR connection', connection);
    });
    this.notificationCenterService.fetchNotificationCenter(5).pipe(takeUntilDestroyed(this._destroyRef)).subscribe();
  }
}
