<!--Mobile card-->

<div class="modal-content text-black bg-white" *ngIf="barcode$ | async as barcode">
  <div class="modal-body p-0 flex-column align-items-center d-flex">
    <div class="card-name text-primary fw-bold mb-1 text-uppercase font-size-14" *ngIf="member$ | async as memberDetails">
      {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
    </div>
    <div class="account-num-header text-black" *ngIf="card$ | async as memberCard">
      <span class="font-size-12">{{ 'barcode.memberid' | translate }} </span>
      <span class="font-size-12">{{ memberCard.ExternalRefNumber }}</span>
    </div>
    <div class="qr px-2">
      <qrcode [qrdata]="barcode.Barcode" [width]="120" [height]="120" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode>
    </div>

    <div class="d-flex align-items-center justify-content-center mb-3">
      <div class="point d-flex flex-column justify-content-center text-center" *ngIf="pointsBalance$">
        <div class="font-size-14 fw-bold text-center">{{ pointsBalance$ | async | number }}</div>
        <div class="font-size-14">{{ 'member.card.points.label' | translate }}</div>
      </div>
      <div class="diver"></div>
      <div class="ecash d-flex flex-column justify-content-center text-center" *ngIf="ecashBalance$">
        <div class="font-size-14 fw-bold text-center">{{ ecashBalance$ | async | FortressCurrencyPipe }}</div>
        <div class="font-size-14">{{ 'member.card.ecash.label' | translate }}</div>
      </div>
    </div>

    <div class="btn btn-black rounded wallet-button mb-3" [routerLink]="'/rewards/wallet'">
      <span class="icon material-icons">account_balance_wallet</span>
      <span class="text-white font-size-14 font-heading ps-2">{{ 'barcode.wallet.button' | translate }}</span>
    </div>
  </div>
</div>
<ng-container *ngIf="member$ | async as member">
  <div class="overflow-hidden shadow member-card text-white" [ngClass]="isCardFozen ? 'bg-frozen-card' : 'bg-primary'">
    <div class="d-flex justify-content-between align-items-center w-100 p-2">
      <div class="fw-bold text-uppercase font-size-14">{{ 'barcode.jetcash.text' | translate }}</div>
      <ng-container *ngIf="cardToken !== undefined; else addCard">
        <div class="d-flex align-items-center justify-content-center text-start card-text p-0">
          <div class="d-flex align-items-center justify-content-center">
            {{ cardToken?.CardNumber | creditCardNumber | slice : -9 }}
            <ng-container [ngSwitch]="cardToken!.CardType">
              <img class="mastercard-logo" *ngSwitchCase="1" src="assets/images/payment-icons/_Mastercard.svg" />
              <img class="mastercard-logo" *ngSwitchCase="2" src="assets/images/payment-icons/_AMEX.svg" />
              <img class="mastercard-logo" *ngSwitchCase="3" src="assets/images/payment-icons/_Visa.svg" />
              <img class="mastercard-logo" *ngSwitchCase="4" src="assets/images/payment-icons/_Discover.svg" />
              <img class="mastercard-logo" *ngSwitchCase="5" src="assets/images/payment-icons/_JCB.svg" />
              <img class="mastercard-logo" *ngSwitchCase="7" src="assets/images/payment-icons/_DinnersClub.svg" />
              <img class="mastercard-logo" *ngSwitchCase="8" src="assets/images/payment-icons/_UnionPay.svg" />
              <div class="mastercard-logo" *ngSwitchDefault></div>
            </ng-container>
            <a class="text-white" [routerLink]="'/ecash'">
              <span class="material-icons">chevron_right</span>
            </a>
          </div>
        </div>
      </ng-container>
      <ng-template #addCard>
        <a [routerLink]="'/ecash'">
          <button class="btn d-flex align-items-center text-white fw-bold justify-content-end">
            <span class="material-icons font-size-20 me-1"> add_card </span>
            <span class="font-size-12"> Add Card</span>
          </button>
        </a>
      </ng-template>
    </div>
  </div>
  <div class="row d-flex justify-content-center align-items-center mt-3">
    <fgb-mobile-pass [memberId]="member.MemberId"></fgb-mobile-pass>
  </div>
  <ng-container *ngIf="showMobilePass">
    <div class="text-lg-center text-start font-size-14 px-2 py-2 text-capitalize">
      {{ 'ecash.page.qr.text' | translate }}
    </div>
  </ng-container>
</ng-container>
