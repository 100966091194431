import { Component } from '@angular/core';

@Component({
  selector: 'fgb-badges-nav',
  templateUrl: './badges-nav.component.html',
  styleUrl: './badges-nav.component.scss'
})
export class BadgesNavComponent {

}
