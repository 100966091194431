import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fgb-short-long-description',
  templateUrl: './short-long-description.component.html',
  styleUrls: ['./short-long-description.component.scss'],
})
export class ShortLongDescriptionComponent implements OnInit {
  @Input() description: string;
  @Input() hiddenLength = 100;
  shorten = true;
  descriptionTooLong = false;

  constructor() {}

  ngOnInit() {
    this.descriptionTooLong = !!this.description && this.description.length > this.hiddenLength;
  }

  toggleReadMore() {
    this.shorten = !this.shorten;
  }
}
