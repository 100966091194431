import { Component, OnInit } from '@angular/core';
import { AuthService } from '@fgb/core';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'fgb-back-to-guardian',
  templateUrl: './back-to-guardian.component.html',
  styleUrls: ['./back-to-guardian.component.scss']
})
export class BackToGuardianComponent implements OnInit {

  constructor(private authService: AuthService) { }

  ngOnInit() {
  }

  switchToGuardian() {
    firstValueFrom(this.authService.switchToGuardian());
  }
}
