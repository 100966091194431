<div class="d-block">
  <a class="company-container mb-3 rounded overflow-hidden border d-block">
    <div class="company-item">
      <div class="company-image d-flex align-items-left">
        <img class="w-100" src="{{ offer.ImageUrl | contentImage : '6' }}" alt="{{ offer.ImageUrl }}" />
      </div>
      <div class="company-info">
        <h5 class="company-title text-black text-capitalize font-size-14 fw-bold" [innerHTML]="languageContent.Name"></h5>
        <div class="company-description-box">
          <div class="company-description text-start text-black" [innerHTML]="languageContent.Description"></div>
        </div>
        <div
          [ngClass]="{
            invisible: offer.OfferType == companyOfferType.CouponCode || offer.OfferType == companyOfferType.NoAction
          }"
          class="offer-link fw-bold font-size-14 text-primary my-3 cursor-pointer"
          (click)="claimOffer(offer.OfferId)"
        >
          {{ 'offers.external.link' | translate }}
        </div>
        <fgb-errors></fgb-errors>
      </div>
    </div>
  </a>
</div>
