<div class="d-lg-block d-none">
  <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
</div>
<!-- Filter options-->
<div class="d-flex flex-column">
  <div class="container order-2 order-lg-1">
    <div class="description mb-3 my-lg-0">
      <div class="text fw-bold">{{ 'rewards.description' | translate }}</div>
    </div>
  </div>
  <div class="mb-3 order-1 order-lg-2">
    <fgb-filter-modal
      [filterTypesList]="filterTypesList"
      (filterTypesListEmitter)="handleFilterTypesListEmitter($event)"
      [selectedValues]="selectedValues"
      (selectedValuesEmitter)="handleSelectedValuesEmitter($event)"
      [showOffCanvas]="showOffCanvas"
      (showOffCanvasEmitter)="handleShowOffCanvasEmitter($event)"
    >
    </fgb-filter-modal>
  </div>
</div>
<div class="container">
  <fgbcl-mixed-loyalty-list [onlyFavourites]="true" [filterOptionsList]="selectedValues"></fgbcl-mixed-loyalty-list>
</div>
