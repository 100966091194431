import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
@Component({
  selector: 'fgb-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  termsLink: string;
  faqLink: string;
  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.translate
      .stream(['tncs.link', 'faq.link'])
      .pipe(take(1))
      .subscribe((data: any) => {
        this.termsLink = data['tncs.link'];
        this.faqLink = data['faq.link'];
      });
  }
}
