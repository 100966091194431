import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ActionLabels,
  MarketplaceExtendedQuery,
  MarketplaceItemExtended,
  MarketplaceService,
  ProcessProviderId,
} from '@fgb/core';
import { firstValueFrom, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
@Component({
  selector: 'fgb-marketplace-detail-page',
  templateUrl: './marketplace-detail-page.component.html',
  styleUrls: ['./marketplace-detail-page.component.scss'],
})
export class MarketplaceDetailPageComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private marketplaceService: MarketplaceService,
    private marketplaceExtendedQuery: MarketplaceExtendedQuery
  ) {}

  id: number;
  actionLabels = ActionLabels;
  marketplaceItem$: Observable<MarketplaceItemExtended | undefined>;
  marketplaceItemType: string;
  marketplaceItemGroup: string;
  backLink: string = '/rewards';
  backText: string = 'back.to.rewards';
  ngOnInit() {
    this.id = +(this.route.snapshot.paramMap.get('id') || 0);
    this.fetchMarketplace(this.id);
  }

  fetchMarketplace(id: number) {
    firstValueFrom(this.marketplaceService.fetchMarketplaceItemById(id)).then(() => {
      firstValueFrom(
        this.marketplaceExtendedQuery.selectExtendedMarketplaceItem(id).pipe(
          tap((item) => {
            if (item) {
              this._updateMarketplaceItemType(item);
            }
          })
        )
      );
    });
  }

  private _updateMarketplaceItemType(marketplaceItem: MarketplaceItemExtended) {
    switch (marketplaceItem.ProcessProviderId) {
      case ProcessProviderId.Event:
        this.backLink = '/rewards';
        this.marketplaceItemType = 'Event';
        this.marketplaceItemGroup = 'Events';
        this.backText = 'back.to.event';
        break;
      case ProcessProviderId.VirtualEvent:
        this.backLink = '/rewards';
        this.marketplaceItemType = 'Event';
        this.marketplaceItemGroup = 'Events';
        this.backText = 'back.to.event';
        break;
      case ProcessProviderId.Product:
        this.backLink = '/rewards';
        this.marketplaceItemType = 'Marketplace';
        this.marketplaceItemGroup = 'Marketplace';
        this.backText = 'back.to.marketplace';
        break;
      default:
        this.marketplaceItemType = 'Marketplace';
        this.marketplaceItemGroup = 'Marketplace';
    }
  }
}
