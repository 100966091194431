import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';

@Component({
  selector: 'fgb-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
})
export class NumberInputComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isActive: boolean = true;
  @Input() minValue: number = 1;
  @Input() maxValue: number;
  @Input() incrementValue: number;
  @Input() minMessage: string;
  @Input() maxMessage: string;
  @Input() amount: number;
  @Input() theme?: string;
  @Input() limitValueToRange: boolean = true;
  @Output() amountChange = new EventEmitter();
  @Input() outOfRange: boolean = false;
  @Output() outOfRangeChange = new EventEmitter<boolean>();
  amountSubject: Subject<number> = new Subject<number>();
  onAmountEnter: Subscription;
  showMinMessage = false;
  showMaxMessage = false;

  constructor() {}

  ngOnInit() {
    if (!this.amount) {
      this.amount = this.minValue;
      this.amountSubject.next(this.amount);
    }

    this.onAmountEnter = this.amountSubject
      .asObservable()
      .pipe(
        filter((x) => x !== this.amount),
        debounceTime(300),
        map((x) => {
          x = this.validateAmount(x, true);
          return x;
        })
      )
      .subscribe((x) => {
        this.amount = x;
        this.amountSubject.next(this.amount);
        this.amountChange.emit(this.amount);
        this.outOfRangeChange.emit(this.isNumberOutOfRange());
      });
  }

  ngOnDestroy(): void {
    if (this.onAmountEnter) {
      this.onAmountEnter.unsubscribe();
    }
  }

  ngOnChanges() {
    this.amount = this.validateAmount(this.amount, false);
  }

  increment() {
    let amount = this.amount;
    if (this.amount < this.minValue) {
      amount = this.minValue;
    } else if (this.amount + this.incrementValue <= this.maxValue) {
      amount = this.amount + this.incrementValue;
    }
    this.showMinMessage = false;
    this.amountSubject.next(amount);
    this.amountChange.emit(amount);
  }

  decrement() {
    let amount = this.amount;
    if (this.amount > this.maxValue) {
      amount = this.maxValue;
    } else if (this.amount - this.incrementValue >= this.minValue) {
      amount = this.amount - this.incrementValue;
    }
    this.showMaxMessage = false;
    this.amountSubject.next(amount);
    this.amountChange.emit(amount);
  }

  isNumberOutOfRange(): boolean {
    return this.amount < this.minValue || this.amount > this.maxValue;
  }

  /** Valdiates the amount compared to the min and max value inputs and returns the correct amount after comparison */
  private validateAmount(x: number, showMessages: boolean): number {
    if (showMessages) {
      this.showMaxMessage = false;
      this.showMinMessage = false;
    }
    if (x > this.maxValue) {
      if (this.limitValueToRange) {
        x = this.maxValue;
      }
      if (showMessages) {
        this.showMaxMessage = true;
      }
    } else if (x < this.minValue) {
        if (this.limitValueToRange) {
          x = this.minValue;
        }
        if (showMessages) {
          this.showMinMessage = true;
        }
    } else {
      this.showMaxMessage = false;
      this.showMinMessage = false;
    }
    return x;
  }
}
