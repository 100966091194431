<div class="d-block d-lg-none">
  <div class="container py-3">
    <fgb-member-card></fgb-member-card>
  </div>
</div>

<fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>

<div class="container pb-3">
  <div class="font-size-14 fw-bold text-center border border-disabled py-3 border-rounded">
    {{ 'rewards.sales.info' | translate }}
  </div>
</div>

<div class="container">
  <fgbcl-marketplace-list
    [isList]="true"
    [marketplaceItemView]="true"
    [marketplaceItemTypes]="[0, 1, 2, 4, 5]"
    [defaultSort]="0"
  ></fgbcl-marketplace-list>
</div>
