import { Component, OnInit, Input } from '@angular/core';
import { Company } from '@fgb/core';

@Component({
  selector: 'fgb-company-list-item',
  templateUrl: './company-list-item.component.html',
  styleUrls: ['./company-list-item.component.scss', '../company.scss']
})
export class CompanyListItemComponent implements OnInit {
  @Input() company: Company;
  @Input() ignoreLink: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
