<div class="department-container card shadow rounded overflow-hidden">
  <div class="row no-gutters">
    <div class="col-12 col-lg-6">
      <div class="department-image">
        <img class="w-100" src="{{ department.ImageUrl | contentImage: '1' }}" alt="{{ department.ImageUrl }}" />
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="department-content">
        <div class="text-primary department-title font-size-14 fw-bold text-start">{{ department.DisplayName }}</div>
        <div class="description-container">
          <div class="description font-size-12 overflow-hidden text-start text-info" [innerHTML]="department.Description"></div>
          <div class="read-more-container text-primary">
            <a class="read-more-link font-size-12 fw-bold text-start" (click)="open(readMoreModal)">See more</a>
          </div>
        </div>
        <div class="mt-3 mt-lg-2 mb-1 mb-lg-0">
          <a
            class="btn-primary button shop-now font-size-14 text-center d-flex justify-content-center align-items-center rounded text-white fw-bold"
            (click)="processAffiliate()"
          >
            <span class="icon icon-cart-1 me-2"></span>Shop Now
          </a>
        </div>
        <ng-template #readMoreModal let-modal>
          <div class="flex-container">
            <button type="button" class="modal-close-button text-black border-0" (click)="close()">
              <span class="icon-cancel-circle"></span>
            </button>
            <div class="modal-department-image">
              <img class="w-100" src="{{ department.ImageUrl | contentImage: '1' }}" alt="{{ department.ImageUrl }}" />
            </div>
            <div class="department-title font-size-14 fw-bold text-primary text-center mt-3 mb-2 px-2">
              {{ department.DisplayName }}
            </div>
            <div class="description-container p-2 px-4">
              <div class="modal-description font-size-12 text-center text-info" [innerHTML]="department.Description"></div>
            </div>
            <div class="p-2 my-2 mb-1">
              <a
                class="btn-primary button shop-now font-size-14 text-center d-flex justify-content-center align-items-center rounded text-white fw-bold"
                (click)="processAffiliate()"
              >
                <span class="icon icon-cart-1 me-2"></span>Shop Now
              </a>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
