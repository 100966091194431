<ng-container *ngIf="memberCard$ | async as memberCard; else loadingRef">
  <ng-container *ngIf="memberDetails$ | async as memberDetails; else loadingRef">
    <div class="member-card bg-primary text-white p-3">
      <div class="d-none d-lg-block">
        <div class="card-id text-lg-left text-center pt-2">
          <div class="card-name">{{ memberDetails.FirstName }} {{ memberDetails.Surname }}</div>
        </div>
        <div class="card-account-no text-lg-left text-center pt-1">
          <label>{{ 'member.card.account.number' | translate }} {{ memberCard.ExternalRefNumber }}</label>
        </div>

        <hr />
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-6 border-right border-white text-start">
            <div class="text-start line-height-18 pt-1">
              <label class="subheading-text text-uppercase">{{ 'member.card.points.label' | translate }}</label>
              <div class="balance-text">{{ pointsBalance$ | async | number }}</div>
            </div>
          </div>
          <div class="col-6 text-start ps-4 line-height-18 pt-1">
            <label class="subheading-text text-uppercase">{{ 'member.card.ecash.label' | translate }}</label>
            <div class="balance-text">{{ ecash$ | async | FortressCurrencyPipe }}</div>
          </div>
        </div>
      </div>
      <!--Mobile card-->
      <div class="d-block d-lg-none">
        <div class="card-name fw-bold">{{ memberDetails.FirstName }} {{ memberDetails.Surname }}</div>

        <label class="card-account-no mb-1"
          >{{ 'member.card.account.number' | translate }} {{ memberCard.ExternalRefNumber }}</label
        >

        <hr />
        <div class="d-flex justify-content-between align-items-center">
          <div class="points">
            <label class="subheading-text text-capitalize">{{ 'member.card.points.label' | translate }}</label>
            <div class="balance-text">{{ pointsBalance$ | async | number }}</div>
          </div>

          <div class="ecash">
            <label class="subheading-text text-capitalize">{{ 'member.card.ecash.label' | translate }}</label>
            <div class="balance-text">{{ ecash$ | async | FortressCurrencyPipe }}</div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #loadingRef>
  <fgb-loading></fgb-loading>
</ng-template>
