import { Component, OnInit } from '@angular/core';
import { GameQuery, Game, GameDataSource } from '@fgb/core';
import { Observable } from 'rxjs';

import * as _moment from 'moment';
import { map } from 'rxjs/operators';
const moment = _moment;

@Component({
  selector: 'fgb-next-game-countdown',
  templateUrl: './next-game-countdown.component.html',
  styleUrls: ['./next-game-countdown.component.scss'],
})
export class NextGameCountdownComponent implements OnInit {
  nextGame$: Observable<Game | null>;

  constructor(private gameQuery: GameQuery) {}

  ngOnInit() {
    this.nextGame$ = this.gameQuery.selectAllGamesByDataSource(GameDataSource.Club).pipe(
      // Get all future games
      map((games) => games.filter((game) => moment.utc(game.Date).isAfter(moment.utc()))),
      // Order by date
      map((games) => games.sort((first, second) => moment(first.Date).diff(moment.utc(second.Date)))[0])
    );
  }
}
