import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Renderer2,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {
  config,
  MemberDetails,
  MemberQuery,
  BalanceQuery,
  ProviderName,
  MemberCard,
  MemberCardQuery,
  KeyValuePair,
  NotificationCenterService,
  NotificationCenterQuery,
  Cart,
  CartQuery,
  Card,
  CardQuery,
} from '@fgb/core';
import { CardService } from '@fgb/core';
import { AuthService } from '@fgb/core';
import { Observable, Subject, fromEvent, firstValueFrom } from 'rxjs';
import { tap, map, takeUntil } from 'rxjs/operators';
import { Populations } from 'src/app/shared/utilities/populations';
import { ProductCode } from 'src/app/shared/utilities/product-code';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fgb-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('greedyNav', { static: true }) greedyNav: ElementRef;
  @ViewChild('greedyDropdown', { static: true }) greedyDropdown: ElementRef;
  breakPoints: number[] = [];
  shownLinks: number = 0;
  hiddenLinks: number = 0;
  populations = Populations;
  productcode = ProductCode;

  memberDetails$: Observable<MemberDetails | undefined>;
  combinedCard$: Observable<number | undefined>;
  loyaltyPointsCard$: Observable<number | undefined>;
  card$: Observable<MemberCard | undefined>;
  unseenNotificationCount$: Observable<number>;
  isCollapsed = false;
  sub: any;
  name$: Observable<string>;
  selectedMember: string;
  cards$: Observable<Card[]>;
  totalItems$: Observable<number>;
  basketPointsTotal$: Observable<number | undefined>;
  items$: Observable<Cart[] | undefined>;
  currentLanguage: string = '';
  private _destroyed$ = new Subject<void>();
  @ViewChild('greedyNav', { static: false }) navBar: ElementRef<HTMLElement>;
  @ViewChild('greedyDropdown', { static: true }) moreDropdown: ElementRef<HTMLElement>;
  @ViewChild('moreBtn', { static: false }) moreBtn: ElementRef<HTMLElement>;
  constructor(
    private translate: TranslateService,
    public router: Router,
    public cardService: CardService,
    public memberQuery: MemberQuery,
    private authService: AuthService,
    private balanceQuery: BalanceQuery,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private memberCardQuery: MemberCardQuery,
    private cardQuery: CardQuery,
    private notificationService: NotificationCenterService,
    private notificationQuery: NotificationCenterQuery,
    private cartQuery: CartQuery
  ) {}

  useLanguage(language: string) {
    this.translate.use(language);
  }

  ngOnInit() {
    this.loyaltyPointsCard$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
    this.combinedCard$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
    this.card$ = this.memberCardQuery.selectObservedMemberCard();
    this.unseenNotificationCount$ = this.notificationQuery.unseenNotificationsCount$;

    this.initMarketplaceCart();
    this.initAccountSwitch();
    this.initLcid();
  }

  ngAfterViewInit() {
    this._init();
    fromEvent(window, 'resize')
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => {
        this._updateNavigationMenu();
      });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private _init(): void {
    if (!this.navBar) {
      return;
    }
    const navItems = [...Object.values(this.navBar.nativeElement.children)];
    this.breakPoints = navItems.map((e) => e.getBoundingClientRect().right);
    setTimeout(() => {
      this._initNavItems();
      this._updateNavigationMenu();
    });
  }

  private _initNavItems(): void {
    const navItems = Object.values(this.navBar.nativeElement.children);

    // Creates copy of the nav link items and add it to the more button dropdown menu
    navItems.forEach((navItem) => {
      this.renderer.setStyle(navItem, 'display', 'none');

      if (!navItem.classList.contains('more-dropdown')) {
        this.renderer.setStyle(navItem, 'visibility', 'visible');

        if (this.moreDropdown) {
          const el = navItem.cloneNode(true) as any;
          this.renderer.listen(el, 'click', (event) => {
            if (event.target?.getAttribute('ng-reflect-router-link')) {
              event.preventDefault();

              if (event.target?.getAttribute('href')) {
                this.router.navigate([`/${event.target?.getAttribute('href')}`]);
              }
            }
          });
          this.renderer.appendChild(this.moreDropdown.nativeElement, el);
        }
      }

      if (navItem.classList.contains('more-dropdown')) {
        this.renderer.setStyle(navItem, 'display', 'flex');
      }
    });
  }

  initAccountSwitch() {
    this.name$ = this.memberQuery.selectMemberDetails().pipe(
      map((m) => {
        if (m) {
          return `${m.FirstName} ${m.Surname}`;
        }
        return '';
      })
    );

    this.selectedMember = this.memberQuery.getUserContext().memberId;
    this.cards$ = this.cardQuery.selectCards().pipe(
      map((cards: Card[]) =>
        cards
          .sort((a, b) => parseInt(a.Seat) - parseInt(b.Seat))
          .sort((a, b) => parseInt(a.Row) - parseInt(b.Row))
          .sort((a, b) => parseInt(a.Area) - parseInt(b.Area))
      )
    );
  }

  initLcid() {
    this.memberQuery.userContext$.pipe(takeUntil(this._destroyed$)).subscribe((user) => {
      if (user && user.lcid) {
        if (user.lcid === '2057') {
          this.currentLanguage = 'EN';
        } else if (user.lcid === '3084') {
          this.currentLanguage = 'FR';
        } else if (user.lcid === '1037') {
          this.currentLanguage = 'HE';
        } else if (user.lcid === '1033') {
          this.currentLanguage = 'US';
        }
      }
    });
  }

  initMarketplaceCart() {
    this.totalItems$ = this.cartQuery.updateTotalItems();
    this.basketPointsTotal$ = this.cartQuery.updateBasketPointsTotal();
    this.items$ = this.cartQuery.selectAllMarketplaceCartData();
  }

  private _updateNavigationMenu(): void {
    if (!this.navBar) {
      return;
    }

    const nav = this.navBar.nativeElement.offsetLeft + this.navBar.nativeElement.clientWidth;
    const navItems = Object.values(this.navBar.nativeElement.children).filter((el) => !el.classList.contains('more-dropdown'));
    const dropdownBtn = Object.values(this.navBar.nativeElement.children).find((el) => el.classList.contains('more-dropdown'));
    const moreBtnWidth = this.moreBtn?.nativeElement.getBoundingClientRect().width || 0;
    const dropdownItems = Object.values(this.moreDropdown?.nativeElement.children || []);

    if (!this.moreDropdown) {
      navItems.forEach((navItem) => {
        this.renderer.setStyle(navItem, 'display', 'flex');
      });
      return;
    }

    navItems.forEach((navItem, idx) => {
      if (this.breakPoints[idx] <= nav - moreBtnWidth) {
        this.renderer.setStyle(navItem, 'display', 'flex');
        this.renderer.removeClass(navItem, 'hidden');
        this.renderer.setStyle(dropdownItems[idx], 'display', 'none');
      } else {
        this.renderer.setStyle(navItem, 'display', 'none');
        this.renderer.addClass(navItem, 'hidden');
        this.renderer.setStyle(dropdownItems[idx], 'display', 'flex');
      }
    });

    if (this.moreDropdown) {
      if (dropdownItems.length > 0) {
        if (navItems.filter((item) => item.classList.contains('hidden')).length > 0) {
          this.renderer.setStyle(dropdownBtn, 'visibility', 'unset');
        } else {
          this.renderer.setStyle(dropdownBtn, 'visibility', 'hidden');
        }
      } else {
        this.renderer.setStyle(dropdownBtn, 'visibility', 'hidden');
      }
    }
  }

  calculateDropdown() {
    const navElement: HTMLElement = this.greedyNav.nativeElement;
    const dropdownWidth = navElement.children[navElement.children.length - 1].clientWidth;
    const spaceAvailable = navElement.clientWidth - dropdownWidth;
    if (navElement.clientWidth > 0 && this.breakPoints.length === 0) {
      this.initGreedyNav();
    }
    if (
      // shrink the nav
      this.shownLinks > 0 &&
      spaceAvailable <= this.breakPoints[this.shownLinks - 1]
    ) {
      this.shownLinks--;
      this.hiddenLinks++;

      this.updateGreedyNav();
      this.calculateDropdown();
    } else if (
      // grow the nav
      this.hiddenLinks > 0 &&
      spaceAvailable > this.breakPoints[this.shownLinks]
    ) {
      this.shownLinks++;
      this.hiddenLinks--;

      this.updateGreedyNav();
      this.calculateDropdown();
    } else {
      this.updateGreedyNav();
    }
  }

  initGreedyNav() {
    // recalculate on resize
    const navElement: HTMLElement = this.greedyNav.nativeElement;
    let totalWidth = 0;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < navElement.children.length - 1; i++) {
      totalWidth += navElement.children[i].clientWidth;
      if (totalWidth > 0) {
        this.breakPoints[i] = totalWidth;
      }
    }
    this.shownLinks = navElement.children.length - 1;
    this.hiddenLinks = 0;
  }

  updateGreedyNav() {
    const navElement: HTMLElement = this.greedyNav.nativeElement;
    const dropdownElement: HTMLElement = this.greedyDropdown.nativeElement;

    for (let i = 0; i < dropdownElement.children.length; i++) {
      if (i < this.shownLinks) {
        this.renderer.addClass(dropdownElement.children[i], 'hide');
        this.renderer.removeClass(navElement.children[i], 'hide');
      } else {
        this.renderer.addClass(navElement.children[i], 'hide');
        this.renderer.removeClass(dropdownElement.children[i], 'hide');
      }
    }
    this.cdr.detectChanges();
  }

  logout() {
    firstValueFrom(this.authService
      .logout()
      .pipe(tap(() => this.router.navigate(['/login']))));
  }

  selectCard() {
    this.router.navigate(['/login']);
  }

  LoginThirdParty() {
    let options = [{ key: 'Url', value: 'https://sdp.sportsbingo.ballparkapps.com/?bingoUser={1}' }] as KeyValuePair[];
    this.authService.loginThirdParty(ProviderName.SidelineSports, 1, '', true, options);
  }

  toggleNotificationCenter(open: boolean) {
    this.notificationService.updateIsOpen(open);
  }

  loginWithCard(memberId: string) {
    firstValueFrom(this.authService.switchUser(memberId));
  }

  closeDropDown(dropdown: NgbDropdown) {
    dropdown.close();
  }
}
