<div class="card shadow border-0 mb-3" *ngIf="game">
  <div class="card-body p-0 text-center">
    <div class="game overflow-hidden rounded">
      <div class="teams w-100 d-flex flex-nowrap bg-white position-relative pt-3 px-lg-3 px-1">
        <div class="team-logo d-flex align-items-center w-100 p-0 home-team">
          <img src="{{ getTeamLogoUrl(game.HomeTeamId) }}" draggable="false" />
        </div>
        <div>
          <fgb-countdown-watch [endDateTime]="game.Date"></fgb-countdown-watch>
        </div>
        <div class="team-logo d-flex align-items-center w-100 p-0 away-team">
          <img src="{{ getTeamLogoUrl(game.AwayTeamId) }}" draggable="false" />
        </div>
      </div>
      <div class="game-date-box bg-primary text-white font-size-16 fw-bold">
        {{ game.Date | date: 'HH:mm, EEE MMM dd' }}
        {{ game.AwayTeamId }}
      </div>
    </div>
  </div>
</div>
