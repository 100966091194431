<div class="answer-container d-flex flex-row" [ngClass]="{ selected: answer.UserSelected }">
  <img
    *ngIf="answer.ImageURL"
    class="image-container icon"
    src="{{ answer.ImageURL | contentImage: '6' }}"
    alt="{{ answer.ImageURL }}"
  />
  <div class="answer-text-wrapper w-100 progress">
    <div class="answer-text-landscape text-end">
      {{ answer.Title }}
    </div>
    <div class="answer-text progress-bar text-start fw-bold w-100 h-100">
      <ngb-progressbar [value]="percentage" [showValue]="true"></ngb-progressbar>
    </div>
  </div>
</div>
