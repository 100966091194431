import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ChildViewModel, ChildQuery, ChildService } from '@fgb/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import { firstValueFrom, Subscription } from 'rxjs';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';

@Component({
  selector: 'fgb-child-barcodes-carousel',
  templateUrl: './child-barcodes-carousel.component.html',
  styleUrls: ['./child-barcodes-carousel.component.scss'],
})
export class ChildBarcodesCarouselComponent implements OnInit, OnDestroy {
  customOptions: OwlOptions = {
    items: 1,
    margin: OwlCarouselSettings.Margin,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
  };

  children: ChildViewModel[] = [];
  childrenSubscription: Subscription;
  @ViewChild('owlCar', { static: true }) owlElement: CarouselComponent;
  @Input() public childId: string;

  constructor(private childQuery: ChildQuery, private childService: ChildService, private activeModal: NgbActiveModal) {}

  ngOnInit() {
    firstValueFrom(this.childService.fetchChildren());

    this.childrenSubscription = this.childQuery.selectChildren().subscribe((children) => {
      this.children = children;
    });
  }

  ngOnDestroy() {
    if (this.childrenSubscription) {
      this.childrenSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.owlElement.to(this.childId);
  }

  closeModal(): void {
    this.activeModal.close();
  }
}
