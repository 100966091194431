<fgb-navigation-indicator></fgb-navigation-indicator>
<fgb-popup-notification></fgb-popup-notification>
<fgbcl-notification-toast></fgbcl-notification-toast>

<fgb-mobile-nav-menu></fgb-mobile-nav-menu>
<fgb-nav-bar></fgb-nav-bar>

<div id="main">
  <router-outlet></router-outlet>
</div>

<fgb-footer></fgb-footer>
