import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { LoggingService, PopUpCommandType, PopUpNotificationResponseType, PopUpNotificationType, PopUpNotificationViewModel } from '@fgb/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'fgb-custom-popup',
  templateUrl: './custom-popup.component.html',
  styleUrls: ['./custom-popup.component.scss'],
})
export class CustomPopupComponent implements OnInit, AfterViewInit, OnDestroy {
  popupData: PopUpNotificationViewModel;
  dynamicComponentRef: TemplateRef<any> | undefined;
  actionConfirmation$ = new Subject<boolean>();
  private actionSub: Subscription;

  constructor(public activeModal: NgbActiveModal, private logService: LoggingService) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.cancelIfNotValid();
    this.watchForActionConfirmation();
  }

  /** Subscribes to the actionConfirmation subject to enable or disable action buttons.
   *  For Action Popups, action buttons are disabled by default.
   */
  watchForActionConfirmation(): void {
    this.actionSub = this.actionConfirmation$.subscribe(() => {
      this.emitResponse(PopUpNotificationResponseType.Accepted);
    });
  }

  ngOnDestroy() {
    this.actionSub.unsubscribe();
  }

  /** Cancels popup if the template is not defined when notification type is 1 (action) */
  cancelIfNotValid(): void {
    if (this.isActionPopup() && !this.dynamicComponentRef) {
      this.logService.error('An Action Popup has been called but the template has not been defined');
      this.emitResponse(PopUpNotificationResponseType.Error);
    }
  }

  /** Checks if the notification type is 0 (text) */
  isTextPopup(): boolean {
    return this.popupData.notificationType === PopUpNotificationType.TextBased;
  }

  /** Checks if the notification type is 1 (action) */
  isActionPopup(): boolean {
    return this.popupData.notificationType === PopUpNotificationType.ActionBased;
  }

  /** Checks if the command type is 0 (Confirm button only) */
  isActionConfirm(): boolean {
    return this.popupData.commandType === PopUpCommandType.Confirm;
  }

  /** Checks if the command type is 1 (OK Cancel buttons) */
  isActionOKCancel(): boolean {
    return this.popupData.commandType === PopUpCommandType.OkCancel;
  }

  /** Closes dialog box and emits response received from action component
   * @param res The response sent by the popup
   */
  emitResponse(res: PopUpNotificationResponseType): void {
    this.activeModal.close(res);
  }
}
