import { Component, OnInit } from '@angular/core';
import { ErrorStateService, MailingService, EmailRequestParameterModel, EmailModel, EmailKind, SqlDbType } from '@fgb/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'fgb-refer-a-friend',
  templateUrl: './refer-a-friend.component.html',
  styleUrls: ['./refer-a-friend.component.scss']
})
export class ReferAFriendComponent implements OnInit {
  loading: boolean = false;
  referAFriendForm: FormGroup;

  constructor(
    private mailingService: MailingService,
    private errorStateService: ErrorStateService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.referAFriendForm = this.formBuilder.group({
      friendName: new FormControl('', Validators.required),
      friendEmail: new FormControl('', [Validators.required, Validators.email]),
      friendPhone: new FormControl('', Validators.required),
      reason: new FormControl('', Validators.required)
    });
  }

  get f() {
    return this.referAFriendForm.controls;
  }

  sendReferral(formDate: any) {
    this.loading = true;

    let parameters: EmailRequestParameterModel[] = [
      {
        ParameterName: 'FriendName',
        ParameterType: SqlDbType.VarChar,
        ParameterValue: formDate.friendName
      },
      {
        ParameterName: 'FriendEmail',
        ParameterType: SqlDbType.VarChar,
        ParameterValue: formDate.friendEmail
      },
      {
        ParameterName: 'FriendPhone',
        ParameterType: SqlDbType.VarChar,
        ParameterValue: formDate.friendPhone
      },
      {
        ParameterName: 'Reason',
        ParameterType: SqlDbType.VarChar,
        ParameterValue: formDate.reason
      }
    ];

    const emailModel: EmailModel = {
      EmailKind: EmailKind.ReferAFriend,
      Parameters: parameters
    };

    firstValueFrom(this.mailingService
      .sendEmail(emailModel))
      .then(() => {
        this.router.navigate(['referAFriend/success']);
      })
      .catch(err => {
        this.errorStateService.addError(err);
      })
      .finally(() => (this.loading = false));
  }
}
