<div class="card border-0 shadow">
  <div class="card-header fw-bold bg-white border-0 px-2 pb-1 pt-3 font-size-12">
    ENTER YOUR VOUCHER CODE
  </div>
  <div class="card-body pt-2">
    <input
      type="text"
      [(ngModel)]="voucherCode"
      placeholder="ENTER CODE HERE"
      class="form-control text-center border-0 bg-muted font-size-14"
      (keyup.enter)="redeemVoucher()"
    />
    <div class="result-box">
      <div class="result my-1">
        <fgb-errors></fgb-errors>
        <div class="font-italic" *ngIf="redemptionResult">
          {{ redemptionResult }}
        </div>
      </div>
      <button
        class="btn btn-primary form-control voucher-submit bg-primary text-white border-0 fw-bold font-size-14"
        (click)="redeemVoucher()"
      >
        Submit
      </button>
    </div>
  </div>
</div>
