import { Component, OnInit } from '@angular/core';
import { CompanyQuery } from '@fgb/core';
import { Company } from '@fgb/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-company-page',
  templateUrl: './company-page.component.html',
  styleUrls: ['./company-page.component.scss']
})
export class CompanyPageComponent implements OnInit {
  companies$: Observable<Company[]>;

  constructor(
    private companyQuery: CompanyQuery
  ) { }

  ngOnInit() {
    this.companies$ = this.companyQuery.selectCompanies();
  }
}
