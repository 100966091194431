<div class="mobile-nav-menu d-flex position-fixed flex-column d-lg-none">
  <!-- NAV BAR -->
  <div class="mobile-nav-bar d-flex justify-content-between">
    <a class="nav-item fw-bold brand p-1" [routerLink]="['/']" routerLinkActive="router-link-active">
      <img class="home-logo" src="assets/images/logo.png" draggable="false" />
    </a>
    <a class="nav-item" [routerLink]="['/ecash']">
      <span class="material-icons font-size-24"> credit_card </span>
    </a>
    <a class="nav-item" [routerLink]="['/rewards']">
      <span class="material-icons font-size-24"> redeem </span>
    </a>
    <div class="notification-button-wrapper">
      <fgb-notification-button [size]="24" />
    </div>
    <button class="nav-item fw-bold navbar-toggler m-0 px-2" (click)="isCollapsed = !isCollapsed">
      <span class="icon material-icons font-size"> menu </span>
    </button>
  </div>

  <!-- NAV SCREEN -->
  <div [ngbCollapse]="isCollapsed" class="mobile-nav-menu-dropdown">
    <div class="d-flex flex-column p-3 mobile-nav-menu-content">
      <!-- Close Button -->
      <button class="close-button position-absolute border-0" (click)="isCollapsed = !isCollapsed">
        <i class="material-icons close-btn text-white">close</i>
      </button>

      <!-- Club Logo -->
      <div class="d-flex align-items-center justify-content-start mt-2 mb-3">
        <img class="brand" src="assets/images/logo.png" draggable="false" />
      </div>
      <div class="text-start" *ngIf="memberDetails$ | async as memberDetails">
        <h5 class="text-white text-capitalize mb-0 member-name fw-bold">
          {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
        </h5>
        <div class="mt-1 account-num-header text-white" *ngIf="memberCard$ | async as memberCard">
          <span class="font-size-12">{{ 'mobile.nav.memberId' | translate }} </span>
          <span class="font-size-12">{{ memberCard.MemberId }}</span>
        </div>
      </div>

      <div class="monetary-row text-white">
        <hr class="border border-white" />
        <div class="d-flex align-items-center justify-content-start">
          <div class="d-flex flex-column justify-content-start" *ngIf="loyaltyPointsCard$">
            <div class="points">
              <div class="font-size-14 fw-bold">{{ loyaltyPointsCard$ | async | number }}</div>
              <div class="font-size-12">{{ 'member.card.points.label' | translate }}</div>
            </div>
          </div>
          <div class="diver"></div>
          <div class="ecash d-flex flex-column justify-content-start" *ngIf="combinedCard$">
            <div class="font-size-14 fw-bold">{{ combinedCard$ | async | FortressCurrencyPipe }}</div>
            <div class="font-size-12">{{ 'member.card.ecash.label' | translate }}</div>
          </div>
        </div>
        <hr class="border border-white" />
      </div>

      <div class="mobile-nav-button-container">
        <!-- Nav Buttons -->
        <a
          class="nav-item"
          [routerLink]="['/']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="onRouterCheck()"
        >
          <div class="content">
            <span class="material-icons"> home </span>
            <div class="nav-text">{{ 'nav.home' | translate }}</div>
          </div>

          <span class="material-icons"> chevron_right </span>
        </a>

        <a
          class="nav-item"
          [routerLink]="['/ecash']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="onRouterCheck()"
        >
          <div class="content">
            <span class="material-icons"> credit_card</span>
            <div class="nav-text">{{ 'nav.ecash' | translate }}</div>
          </div>

          <span class="material-icons"> chevron_right </span>
        </a>

        <a
          class="nav-item"
          [routerLink]="['/rewards']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="onRouterCheck()"
        >
          <div class="content">
            <span class="material-icons"> redeem</span>
            <div class="nav-text">{{ 'nav.rewards' | translate }}</div>
          </div>

          <span class="material-icons"> chevron_right </span>
        </a>

        <a
          class="nav-item"
          [routerLink]="['/offers']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="onRouterCheck()"
        >
          <div class="content">
            <span class="material-icons"> loyalty</span>
            <div class="nav-text">{{ 'nav.offers' | translate }}</div>
          </div>

          <span class="material-icons"> chevron_right </span>
        </a>

        <a
          class="nav-item"
          [routerLink]="['/rewards/wallet']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="onRouterCheck()"
        >
          <div class="content">
            <span class="material-icons"> account_balance_wallet</span>
            <div class="nav-text">{{ 'nav.wallet' | translate }}</div>
          </div>

          <span class="material-icons"> chevron_right </span>
        </a>

        <a
          class="nav-item"
          [routerLink]="['/earn']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="onRouterCheck()"
        >
          <div class="content">
            <span class="material-icons"> money</span>
            <div class="nav-text">{{ 'nav.earn' | translate }}</div>
          </div>

          <span class="material-icons"> chevron_right </span>
        </a>
        <a
          class="nav-item"
          [routerLink]="['/contact']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="onRouterCheck()"
        >
          <div class="content">
            <span class="material-icons"> email</span>
            <div class="nav-text">{{ 'nav.contact.us' | translate }}</div>
          </div>

          <span class="material-icons"> chevron_right </span>
        </a>

        <a class="nav-item" href="{{ 'terms.link' | translate }}" target="_blank" (click)="onRouterCheck()">
          <div class="content">
            <span class="material-icons"> description</span>
            <div class="nav-text">{{ 'nav.terms.label' | translate }}</div>
          </div>

          <span class="material-icons"> chevron_right </span>
        </a>
        <a class="nav-item" href="{{ 'faqs.link' | translate }}" target="_blank" (click)="onRouterCheck()">
          <div class="content">
            <span class="material-icons"> help</span>
            <div class="nav-text">{{ 'nav.faq.label' | translate }}</div>
          </div>

          <span class="material-icons"> chevron_right </span>
        </a>

        <a class="nav-item" (click)="logout()">
          <div class="content">
            <span class="material-icons"> power_settings_new</span>
            <div class="nav-text">{{ 'nav.log.out' | translate }}</div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
