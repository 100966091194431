import { Component, OnInit } from '@angular/core';
import { PurchaseService, ExternalProviderService } from '@fgb/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { MarketplaceVirtualEvent } from '@fgb/core';

@Component({
  selector: 'fgb-zoom-meeting-page',
  templateUrl: './zoom-meeting-page.component.html',
  styleUrls: ['./zoom-meeting-page.component.scss'],
})
export class ZoomMeetingPageComponent implements OnInit {
  zoomConference$: Observable<MarketplaceVirtualEvent>;
  logItemId: number;
  validItem: boolean = false;
  token: string | null;

  constructor(
    private purchaseService: PurchaseService,
    private route: ActivatedRoute,
    private externalProviderService: ExternalProviderService
  ) {}

  ngOnInit() {
    this.logItemId = +(this.route.snapshot.paramMap.get('purchaseId') || 0);

    this.zoomConference$ = this.purchaseService.getVirtualEventPurchaseDetails(this.logItemId).pipe(
      map((p) => {
        this.externalProviderService.generateVideoToken(p.ProviderId as number, p.MeetingNumber).then((itemValue) => {
          if (itemValue != null) {
            if (itemValue || itemValue != null) {
              this.token = itemValue;
              this.validItem = true;
            }
          }
        });
        return p;
      })
    );
  }
}
