import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { CartQuery, CartService, Cart, ErrorStateService, MemberDetails, MemberQuery, MemberService } from '@fgb/core';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
@Component({
  selector: 'fgb-cart-checkout-page',
  templateUrl: './cart-checkout-page.component.html',
  styleUrls: ['./cart-checkout-page.component.scss'],
})
export class CartCheckoutPageComponent implements OnInit {
  totalPoints: number;
  items$: Observable<Cart[] | undefined>;
  editingMemberAddress: boolean = false;
  missingDetails: string = '';
  memberDetail$: Observable<MemberDetails | undefined>;
  memberAddressForm: FormGroup;
  portalId = '';
  hasSubmittedMemberAddress: boolean = false;
  memberDetails: MemberDetails;
  basketPointsTotal$: Observable<number | undefined>;

  constructor(
    private location: Location,
    private cartQuery: CartQuery,
    private cartService: CartService,
    private errorService: ErrorStateService,
    private memberQuery: MemberQuery,
    private formBuilder: FormBuilder,
    private memberService: MemberService
  ) {}

  ngOnInit() {
    this.errorService.clearErrors();

    this.items$ = this.cartQuery.selectAllMarketplaceCartData().pipe(
      tap(() => {
        this.basketPointsTotal$ = this.cartQuery.updateBasketPointsTotal().pipe(
          tap((val) => {
            if (val) {
              this.totalPoints = val;
            }
          })
        );
      })
    );

    // get address from member details
    this.getMemberDetailAddress();
  }

  // go back to previous page from checkout page
  goBack() {
    this.location.back();
  }

  // make payment on checkout page, show error if member address data is invalid
  payNow() {
    this.errorService.clearErrors();
    if (!this.memberAddressForm.valid) {
      this.missingDetails = 'Please ensure there is no missing address details and that your phone number only contain numbers';
      this.errorService.addError(this.missingDetails);
    } else {
      this.errorService.clearErrors();
      this.cartService.purchaseMarketplaceItemsInCart(this.totalPoints);
    }
  }

  /** Start editing the user's member details address. */
  editMemberAddress() {
    this.editingMemberAddress = true;
  }

  /** Clear the member details address form fields. */
  clearAddressForm(addressForm: FormGroup) {
    addressForm.patchValue({
      Street: '',
      Town: '',
      County: '',
      Postcode: '',
      Country: '',
      HomeNumber: '',
    });
  }

  // cancel button which will close and rebuild the form with the original data
  cancelAndRebuildForm() {
    this.editingMemberAddress = false;

    this.memberDetail$ = this.memberQuery.selectMemberDetails().pipe(
      tap((md) => {
        if (md) {
          this.portalId = md.PortalId;
          this.memberAddressForm = this.formBuilder.group({
            AddressName: [md.AddressName],
            Street: [md.Street, Validators.required],
            Town: [md.Town, Validators.required],
            County: [md.County, Validators.required],
            Country: [md.Country, Validators.required],
            PostCode: [md.PostCode, Validators.required],
            HomeNumber: [md.HomeNumber, Validators.required],
          });
        }
      })
    );
  }

  getMemberDetailAddress() {
    this.memberDetail$ = this.memberQuery.selectMemberDetails().pipe(
      tap((md) => {
        if (md) {
          this.memberDetails = md;
          this.portalId = md.PortalId;
          this.memberAddressForm = this.formBuilder.group({
            AddressName: [md.AddressName],
            Street: [md.Street, Validators.required],
            Town: [md.Town, Validators.required],
            County: [md.County, Validators.required],
            Country: [md.Country, Validators.required],
            PostCode: [md.PostCode, Validators.required],
            HomeNumber: [md.HomeNumber, Validators.required],
          });
        }
      })
    );
  }

  saveMemberDetailAddress() {
    this.hasSubmittedMemberAddress = true;

    if (this.memberAddressForm.valid) {
      this.memberDetails = { ...this.memberDetails, ...this.memberAddressForm.value };

      this.memberService.updateMemberDetails(this.portalId, this.memberDetails);
      this.editingMemberAddress = false;
      this.errorService.clearErrors();
    }
  }
}
