import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Country, Region, LoggingService, CountryQuery } from '@fgb/core';

@Component({
  selector: 'fgb-country-and-regions',
  templateUrl: './country-and-regions.component.html',
  styleUrls: ['./country-and-regions.component.scss'],
})
export class CountryAndRegionsComponent implements OnInit {
  @Input() Form: FormGroup;
  @Input() HasSubmitted: boolean = false;
  @Input() RegionLabel: string = 'Region';
  @Input() SeparateRow: boolean = false;
  countryOptions: Country[];
  regionOptions: Region[] = [];
  selectedCountry: string;
  selectedCounty: string;

  constructor(private logService: LoggingService, private countryQuery: CountryQuery) {}

  ngOnInit() {
    this.countryOptions = this.countryQuery.getAll().sort((a, b) => {
      if (a.Ordinal < b.Ordinal) {
        return -1;
      } else if (a.Ordinal === b.Ordinal) {
        return a.DisplayName.localeCompare(b.DisplayName);
      }
      return 0;
    });
    if (this.Form) {
      if (this.Form.get('Country')) {
        this.selectedCountry = this.Form.get('Country')!.value;
        this.selectedCounty = this.Form.get('County')!.value;
        this.getRegionsByCountry(this.Form.get('Country')!.value);
      }
    }
  }

  getRegionsByCountry(CountryName: string): void {
    let foundCountry = this.countryOptions.find(c => c.CountryName === CountryName);
    if (foundCountry) {
      this.getRegions(foundCountry);

      if (this.selectedCountry !== null) {
        if (this.selectedCountry === foundCountry.DisplayName) {
          this.Form.controls.County.setValue(this.selectedCounty);
        } else {
          this.Form.controls.County.setValue('');
        }
      } else {
        this.Form.controls.County.setValue('');
      }
    } else {
      this.Form.controls.Country.setValue('');
      this.logService.warning('No country data was found for that country');
    }
  }

  getRegions(country: Country): void {
    if (country.Regions) {
      this.regionOptions = [];
    }
    this.regionOptions = country.Regions;
  }
}
