<div class="d-lg-none d-block container my-3">
  <div class="rounded border-0">
    <fgb-barcode [jetsCashDesign]="true" [showMobilePass]="true" [cardToken]="defaultCardToken$ | async"></fgb-barcode>
  </div>
</div>

<div class="d-lg-none d-block">
  <ul class="nav nav-secondary mixed-loyalty-nav">
    <a
      class="nav-item d-flex justify-content-center align-items-center py-1"
      [routerLink]="['/ecash']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <span class="material-icons"> credit_card </span>
      <label class="m-0">{{ 'nav.jet.cash' | translate }}</label>
    </a>
    <a
      class="nav-item d-flex justify-content-center align-items-center px-0 py-1"
      [routerLink]="['/ecashhistory']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <span class="material-icons"> history </span>
      <label class="m-0">{{ 'nav.activity' | translate }}</label>
    </a>
  </ul>
</div>

<div class="container mt-4">
  <div class="d-lg-none d-block">
    <fgb-transaction-history [transactionTypes]="[transactionTypes.payment]" [ecashView]="true"></fgb-transaction-history>
  </div>
</div>
