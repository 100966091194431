import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthGuard,
  PopulationGuard,
  StepType,
  MemberResolver,
  LottoResolver,
  AuctionResolver,
  MemberCardResolver,
  BalanceResolver,
  DepartmentResolver,
  PurchaseResolver,
  AddressBookResolver,
  CardTokenResolver,
  SurveyResolver,
  RegistrationStepResolver,
  LottoWalletResolver,
  AuctionBidsResolver,
  AddressResolver,
  TransactionSuccessGuard,
  SeatingDetailResolver,
  CountryResolver,
  PartnerResolver,
  PartnerPendingResolver,
  MemberLoyaltyStatusResolver,
  GameDataSource,
  firstTimeUserGuard,
} from '@fgb/core';

// General
import { MasterPageComponent } from './pages/master/master-page/master-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { ContactUsPageComponent } from './pages/contact-us-page/contact-us-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { ReferAFriendPageComponent } from './pages/refer-a-friend-page/refer-a-friend-page.component';
import { ReferAFriendSuccessPageComponent } from './pages/refer-a-friend-page/refer-a-friend-success-page/refer-a-friend-success-page.component';
// Loyalty
import { RewardsLayoutComponent } from './pages/rewards-layout/rewards-layout.component';
import { RewardsPageComponent } from './pages/rewards-page/rewards-page.component';
import { AuctionsPageComponent } from './pages/auctions-page/auctions-page.component';
import { AuctionDetailPageComponent } from './pages/auction-detail-page/auction-detail-page.component';
import { MarketplacePageComponent } from './pages/marketplace-page/marketplace-page.component';
import { MarketplaceDetailPageComponent } from './pages/marketplace-detail-page/marketplace-detail-page.component';
import { MarketplacePurchaseSuccessPageComponent } from './pages/marketplace-page/marketplace-purchase-success-page/marketplace-purchase-success-page.component';
import { LottosPageComponent } from './pages/lottos-page/lottos-page.component';
import { LottoDetailPageComponent } from './pages/lotto-detail-page/lotto-detail-page.component';
import { LottosSuccessPageComponent } from './pages/lottos-page/lottos-success-page/lottos-success-page.component';
import { EventsPageComponent } from './pages/events-page/events-page.component';
import { WalletPageComponent } from './pages/wallet-page/wallet-page.component';
import { AuctionBidDetailsPageComponent } from './pages/auction-bid-details-page/auction-bid-details-page.component';
import { LottoWalletDetailsPageComponent } from './pages/lotto-wallet-details-page/lotto-wallet-details-page.component';

// Account
import { AccountDetailsPageComponent } from './pages/account-details-page/account-details-page.component';
import { ActivityPageComponent } from './pages/activity-page/activity-page.component';
import { GuardianPageComponent } from './pages/guardian-page/guardian-page.component';
import { CardPageComponent } from './pages/card-page/card-page.component';
// Ecash
import { EcashPageComponent } from './pages/ecash-page/ecash-page.component';
import { EcashSuccessPageComponent } from './pages/ecash-page/ecash-success-page/ecash-success-page.component';
import { EcashFailurePageComponent } from './pages/ecash-page/ecash-failure-page/ecash-failure-page.component';
import { EcashResultPageComponent } from './pages/ecash-page/ecash-result-page/ecash-result-page.component';
import { EcashHistoryPageComponent } from './pages/ecash-history/ecash-history.component';
// Portal Products
import { PortalProductSuccessPageComponent } from './pages/portal-products-pages/portal-product-success-page/portal-product-success-page.component';
import { PortalProductFailurePageComponent } from './pages/portal-products-pages/portal-product-failure-page/portal-product-failure-page.component';
import { PortalProductResultsPageComponent } from './pages/portal-products-pages/portal-product-results-page/portal-product-results-page.component';

import { ContactsPageComponent } from './pages/contacts-page/contacts-page.component';
// Companies & Departments
import { CompanyPageComponent } from './pages/company-page/company-page.component';
import { CompanyDepartmentsPageComponent } from './pages/company-departments-page/company-departments-page.component';

// Unauthenticated
import { AuthCallbackComponent } from './modules/user/components/auth/auth-callback/auth-callback.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LoginAccountsPageComponent } from './pages/login-accounts-page/login-accounts-page.component';
import { SingleSignOnComponent } from './modules/user/components/single-sign-on/single-sign-on.component';
import { SingleSignOnAppComponent } from './modules/user/components/single-sign-on-app/single-sign-on-app.component';
import { MaintenancePageComponent } from './pages/maintenance-page/maintenance-page.component';
// Registration
import { RegistrationLayoutPageComponent } from './modules/registration/pages/registration-layout-page/registration-layout-page.component';
import { RegistrationPaymentComponent } from './modules/registration/pages/steps/registration-payment/registration-payment.component';
// Population Pages
import { TestPopHomePageComponent } from './pages/test-pop-home-page/test-pop-home-page.component';
// Club Pages
import { EarnPageComponent } from './pages/earn-page/earn-page.component';
import { AuctionBidsPageComponent } from './pages/auction-bids-page/auction-bids-page.component';
import { PointsManagementPageComponent } from './pages/points-management-page/points-management-page.component';
import { TailgatePageComponent } from './pages/tailgate-page/tailgate-page.component';

// Partners Pages
import { PartnerPageComponent } from './pages/partner-page/partner-page.component';
import { LottosTermsPageComponent } from './pages/lottos-terms-page/lottos-terms-page.component';
import { MarketplaceTermsPageComponent } from './pages/marketplace-terms-page/marketplace-terms-page.component';
import { TmCallbackComponent } from './shared/components/manage-tickets/tm-callback/tm-callback.component';
import { TmAuthCallbackComponent } from './shared/components/providers/tm/tm-auth-callback/tm-auth-callback.component';
import { WorksheetPageComponent } from './pages/worksheet-page/worksheet-page.component';
import { ZoomMeetingPageComponent } from './pages/zoom-meeting-page/zoom-meeting-page.component';
import { V1SingleSignOnComponent } from './modules/user/components/v1-single-sign-on/v1-single-sign-on.component';
import { CartCheckoutPageComponent } from './pages/cart-checkout-page/cart-checkout-page.component';
// Offer Pages
import { OffersPageComponent } from './pages/offers-page/offers-page.component';
import { OfferDetailsPageComponent } from './pages/offer-details-page/offer-details-page.component';
import { CartCheckoutResultsPageComponent } from './pages/cart-checkout-page/cart-checkout-results-page/cart-checkout-results-page.component';
import { CartCheckoutSuccessPageComponent } from './pages/cart-checkout-page/cart-checkout-success-page/cart-checkout-success-page.component';
import { NotificationManagementPageComponent } from './pages/notification-management-page/notification-management-page.component';
import { SportsAllianceCallbackComponent } from './shared/components/providers/sports-alliance/sports-alliance-callback/sports-alliance-callback.component';
import { BadgesPageComponent } from './pages/badges-page/badges-page.component';
// import { MarketplaceWalletDetailsComponent } from '@fgb/portal-component-library/src/lib/loyalty/wallet/wallet-v2/marketplace/marketplace-wallet-details';
import { ClaimedPageComponent } from './pages/claimed-page/claimed-page.component';
import { provideRegistrationConfig } from '@fgb/portal-component-library/src/lib/registration';
import { MarketplaceWalletDetailsComponent } from '@fgb/portal-component-library/src/lib/loyalty/wallet';
const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, firstTimeUserGuard()],
    component: MasterPageComponent,
    resolve: {
      member: MemberResolver,
      memberCard: MemberCardResolver,
      balances: BalanceResolver,
    },
    children: [
      {
        path: '',
        canActivate: [PopulationGuard],
        resolve: { cardTokens: CardTokenResolver },
        data: {
          exclude: ['testPop'],
          popRedirect: 'test',
        },
        component: HomePageComponent,
      },
      {
        path: 'guardianPage',
        component: GuardianPageComponent,
        resolve: {
          country: CountryResolver,
          address: AddressResolver,
        },
      },
      {
        path: 'cards',
        component: CardPageComponent,
      },
      {
        path: 'test',
        canActivate: [PopulationGuard],
        data: {
          include: ['testPop'],
          popRedirect: '',
        },
        component: TestPopHomePageComponent,
      },
      {
        path: 'worksheet',
        component: WorksheetPageComponent,
      },
      { path: 'badges', component: BadgesPageComponent },
      {
        path: 'rewards',
        component: RewardsLayoutComponent,
        children: [
          {
            path: '',

            component: RewardsPageComponent,
          },
          {
            path: 'auctions',
            children: [
              {
                path: '',
                resolve: { auctions: AuctionResolver },
                component: AuctionsPageComponent,
              },
              {
                path: 'details/:id',
                resolve: {
                  balances: BalanceResolver,
                  auctions: AuctionResolver,
                },
                component: AuctionDetailPageComponent,
              },
            ],
          },
          {
            path: 'marketplace',
            children: [
              {
                path: '',
                component: MarketplacePageComponent,
              },
              {
                path: 'details/:id',
                children: [
                  {
                    path: '',
                    resolve: {
                      balances: BalanceResolver,

                      cardTokens: CardTokenResolver,
                      country: CountryResolver,
                    },
                    component: MarketplaceDetailPageComponent,
                  },
                  {
                    path: 'terms',
                    component: MarketplaceTermsPageComponent,
                  },
                ],
              },
              {
                path: 'purchase/success',
                canActivate: [TransactionSuccessGuard],
                component: MarketplacePurchaseSuccessPageComponent,
                resolve: {
                  balances: BalanceResolver,
                  purchase: PurchaseResolver,
                },
              },
            ],
          },
          {
            path: 'events',
            component: EventsPageComponent,
          },
          {
            path: 'raffles',
            children: [
              {
                path: '',
                component: LottosPageComponent,
                resolve: { lottos: LottoResolver, balances: BalanceResolver },
              },
              {
                path: 'details/:id',
                children: [
                  {
                    path: '',
                    resolve: { balances: BalanceResolver, lottos: LottoResolver },
                    component: LottoDetailPageComponent,
                  },
                  {
                    path: 'terms',
                    component: LottosTermsPageComponent,
                    resolve: { lottos: LottoResolver },
                  },
                ],
              },
              {
                path: 'purchase/success',
                canActivate: [TransactionSuccessGuard],
                component: LottosSuccessPageComponent,
              },
            ],
          },
          {
            path: 'wallet',
            resolve: { cardTokens: CardTokenResolver },
            children: [
              {
                path: '',
                component: WalletPageComponent,
                resolve: {
                  purchases: PurchaseResolver,
                  lottoWalletItems: LottoWalletResolver,
                  auctionBids: AuctionBidsResolver,
                },
              },
              {
                path: 'details/:id',
                component: MarketplaceWalletDetailsComponent,
                resolve: { purchases: PurchaseResolver },
              },
              {
                path: 'raffledetails/:id',
                component: LottoWalletDetailsPageComponent,
                resolve: { lottoWalletItems: LottoWalletResolver },
              },
              {
                path: 'claimed',
                children: [
                  {
                    path: '',
                    component: ClaimedPageComponent,
                    resolve: {
                      purchases: PurchaseResolver,
                      lottoWalletItems: LottoWalletResolver,
                      auctionBids: AuctionBidsResolver,
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'bids',
            children: [
              {
                path: '',
                component: AuctionBidsPageComponent,
                resolve: { bids: AuctionBidsResolver },
              },
              {
                path: 'details/:id',
                component: AuctionBidDetailsPageComponent,
                resolve: { bids: AuctionBidsResolver },
              },
            ],
          },
        ],
      },
      {
        path: 'account',
        component: AccountDetailsPageComponent,
        resolve: {
          member: MemberResolver,
          balances: BalanceResolver,
          seating: SeatingDetailResolver,
          memberLoyaltyStatus: MemberLoyaltyStatusResolver,
        },
      },
      { path: 'activity', component: ActivityPageComponent },
      {
        path: 'referAFriend',
        children: [
          { path: '', component: ReferAFriendPageComponent },
          { path: 'success', component: ReferAFriendSuccessPageComponent },
        ],
      },
      { path: 'pointsmanagement', component: PointsManagementPageComponent, resolve: { partners: PartnerResolver } },
      {
        path: 'partners',
        component: PartnerPageComponent,
        resolve: { partners: PartnerResolver, pendingPartners: PartnerPendingResolver },
      },
      {
        path: 'ecash',
        resolve: { balances: BalanceResolver, cardTokens: CardTokenResolver, country: CountryResolver },
        children: [
          { path: '', component: EcashPageComponent },
          {
            path: 'success',
            resolve: { balances: BalanceResolver },
            component: EcashSuccessPageComponent,
          },
          { path: 'failure', component: EcashFailurePageComponent },
          { path: 'result', component: EcashResultPageComponent },
        ],
      },
      {
        path: 'ecashhistory',
        resolve: {
          balances: BalanceResolver,
          cardTokens: CardTokenResolver,
          memberCard: MemberCardResolver,
          member: MemberResolver,
        },
        component: EcashHistoryPageComponent,
      },
      {
        path: 'portalproducts',
        resolve: { balances: BalanceResolver, cardTokens: CardTokenResolver },
        children: [
          {
            path: 'success',
            resolve: { balances: BalanceResolver },
            component: PortalProductSuccessPageComponent,
          },
          { path: 'failure', component: PortalProductFailurePageComponent },
          { path: 'result', component: PortalProductResultsPageComponent },
        ],
      },
      {
        path: 'cardtoken',
        children: [
          { path: 'success', component: EcashSuccessPageComponent },
          { path: 'failure', component: EcashFailurePageComponent },
          { path: 'result', component: EcashResultPageComponent },
        ],
      },
      {
        path: 'company',
        children: [
          { path: '', component: CompanyPageComponent },
          {
            path: ':companyId',
            resolve: { departments: DepartmentResolver },
            component: CompanyDepartmentsPageComponent,
          },
        ],
      },
      {
        path: 'offers',
        children: [
          { path: '', component: OffersPageComponent },
          {
            path: 'details/:offerId',
            component: OfferDetailsPageComponent,
          },
        ],
      },

      { path: 'contact', component: ContactUsPageComponent },

      {
        path: 'contacts',
        component: ContactsPageComponent,
        resolve: { addressBookResolver: AddressBookResolver },
      },

      // Club Pages
      {
        path: 'checkout',
        children: [
          {
            path: '',
            component: CartCheckoutPageComponent,
            resolve: {
              member: MemberResolver,
              address: AddressResolver,
              country: CountryResolver,
            },
          },
          {
            path: 'success',
            component: CartCheckoutSuccessPageComponent,
          },
          {
            path: 'results',
            component: CartCheckoutResultsPageComponent,
          },
        ],
        resolve: {
          address: AddressResolver,
        },
      },
      {
        path: 'watch',
        component: TailgatePageComponent,
        data: {
          gameType: GameDataSource.ExternalRef,
        },
        resolve: {
          surveys: SurveyResolver,
        },
      },
      { path: 'notificationmanagement', component: NotificationManagementPageComponent },
      { path: 'earn', component: EarnPageComponent },

      { path: '404page', component: NotFoundPageComponent },
      { path: 'signin-ticketmaster', component: TmCallbackComponent },
    ],
  },
  {
    path: 'zoom/:purchaseId',
    component: ZoomMeetingPageComponent,
    resolve: { member: MemberResolver, purchases: PurchaseResolver },
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginPageComponent },
  { path: 'login/accounts', component: LoginAccountsPageComponent },
  {
    path: 'register',
    resolve: { country: CountryResolver },
    children: [
      {
        path: 'payment',
        children: [
          {
            path: '',
            component: RegistrationPaymentComponent,
            data: { registrationStep: StepType.Payment },
            resolve: { member: MemberResolver, address: AddressResolver, registrationStep: RegistrationStepResolver },
          },
        ],
      },
    ],
  },
  {
    path: 'register',
    component: RegistrationLayoutPageComponent,
    loadChildren: () => import('@fgb/portal-component-library/src/lib/registration').then((m) => m.RegistrationModule),
    providers: [provideRegistrationConfig()],
  },
  { path: 'callback', component: AuthCallbackComponent },

  // App Login
  { path: 'loginApp', component: SingleSignOnAppComponent },
  { path: 'loginApp/:providerId', component: SingleSignOnAppComponent },
  { path: 'loginApp/:providerId/:clubId', component: SingleSignOnAppComponent },

  // Web Login
  { path: 'sso', component: SingleSignOnComponent },
  { path: 'login/singleSignOn/:providerId', component: SingleSignOnComponent },
  { path: 'login/singleSignOn/:providerId/:clubId', component: SingleSignOnComponent },
  { path: 'login/ss/login.aspx', component: V1SingleSignOnComponent },
  { path: 'maintenance', component: MaintenancePageComponent },
  { path: 'tm-auth-callback', component: TmAuthCallbackComponent },
  { path: 'signin-ticketmaster', component: TmCallbackComponent },
  { path: 'sa-callback', component: SportsAllianceCallbackComponent },
  { path: '**', redirectTo: '404page' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
