import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { config, Game } from '@fgb/core';

@Component({
  selector: 'fgb-game-card-countdown',
  templateUrl: './game-card-countdown.component.html',
  styleUrls: ['./game-card-countdown.component.scss'],
})
export class GameCardCountdownComponent implements OnInit {
  @Input() game: Game;

  constructor() {}

  ngOnInit() {}

  getTeamLogoUrl(teamId: number) {
    return `${config.contentUrl}/image/teams/${teamId}__1_1_1033.png`;
  }
}
