<div class="banner">
  <h1>Points Management</h1>
</div>
<fgb-balance-bar></fgb-balance-bar>
<fgb-account-nav></fgb-account-nav>

<div class="container">
  <fgb-points-management-placeholder> </fgb-points-management-placeholder>
</div>
<div class="title">
  <h4 class="text-uppercase text-center">Points Transfer</h4>
</div>
<div class="container">
  <fgb-points-transfer> </fgb-points-transfer>
</div>
