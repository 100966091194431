import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ErrorStateService, PartnerPending, PartnerPendingQuery, PartnerPendingService } from '@fgb/core';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { Regex } from 'src/app/shared/utilities/regex';
@Component({
  selector: 'fgb-partner-add-form',
  templateUrl: './partner-add-form.component.html',
  styleUrls: ['./partner-add-form.component.scss'],
})
export class PartnerAddFormComponent implements OnInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private partnerPendingService: PartnerPendingService,
    private pendingQuery: PartnerPendingQuery,
    private errorService: ErrorStateService
  ) {}
  @Output() close = new EventEmitter();
  addForm: FormGroup;
  pendingPartners$: Observable<PartnerPending[]>;
  pendingPartnerEmails: string[] = [];
  pendingPartnerSub: Subscription;
  ngOnInit() {
    this.addForm = this.fb.group({
      fullName: ['', Validators.required],
      email: ['', [Validators.pattern(Regex.email), Validators.required]],
    });

    this.pendingPartnerSub = this.pendingQuery.selectAll().subscribe((pendingPartners) => {
      pendingPartners.forEach((pendingPatner) => {
        this.pendingPartnerEmails.push(pendingPatner.Email.toLowerCase());
      });
    });
  }

  ngOnDestroy() {
    if (this.pendingPartnerSub) {
      this.pendingPartnerSub.unsubscribe();
    }
  }

  submit() {
    this.errorService.clearErrors();
    let pendingExists = false;
    this.pendingPartnerEmails.forEach((pendingEmail) => {
      if (this.addForm.controls.email.value.toLowerCase() === pendingEmail) {
        pendingExists = true;
        return;
      }
    });

    if (pendingExists) {
      this.errorService.addError('This email is already used for an existing pending partner');
    } else {
      const fullName: string = this.addForm.value.fullName;
      const email: string = this.addForm.value.email;
      firstValueFrom(this.partnerPendingService.add(fullName, email, ''));
      this.close.emit();
    }
  }

  clearErrorsOnClose() {
    this.errorService.clearErrors();
  }
}
