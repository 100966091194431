<div class="banner-checkout d-none d-lg-flex">
  <h5 class="text-white">Checkout</h5>
</div>
<div class="container">
  <div class="back-mobile-bar d-flex d-lg-none my-2">
    <!-- Back Button -->
    <a
      class="btn btn-primary flex-grow-0 flex-shrink-0 d-flex align-items-center justify-content-center h-100 pe-2 px-3 py-2 fw-bold me-2"
      (click)="goBack()"
    >
      <span class="icon-arrow-1-left fw-bold text-white"></span>
    </a>
    <!-- Checkout Title -->
    <div class="justify-content-center flex-grow-1 flex-shrink-1 h-100">
      <div
        class="d-flex align-items-center justify-content-center bg-white text-primary border border-secondary rounded rounded text-center h-100 p-2"
      >
        <h4 class="mb-0 text-secondary font-size-16">Checkout</h4>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-7 col-12 mt-3">
      <h4 class="text-secondary font-size-14">ITEMS</h4>
      <hr class="w-100" />
      <fgbcl-cart-item></fgbcl-cart-item>
      <button class="btn text-primary fw-bold d-none d-lg-block ps-0" (click)="goBack()">
        <span class="font-size-14">&#60; </span><span class="font-size-14">Back</span>
      </button>
    </div>
    <div class="col-lg-5 col-12 mt-3">
      <h4 class="d-none d-lg-block">&nbsp;</h4>
      <!--member details address-->
      <div class="container shadow py-2 mb-3" [ngClass]="{ 'd-none': editingMemberAddress }">
        <div class="position-relative rounded p-2">
          <div class="d-flex justify-content-between">
            <h4 class="text-secondary font-size-14">Address</h4>
          </div>
          <div class="row">
            <div class="col-10">
              <span *ngIf="memberDetail$ | async as member" class="font-size-14 mb-0 d-inline-block text-info">
                <div>{{ member.Street }},</div>
                <div>{{ member.Town }},</div>
                <div>{{ member.County }},</div>
                <div>{{ member.Country }},</div>
                <div>{{ member.PostCode }},</div>
                <div>{{ member.HomeNumber }}</div>
              </span>
            </div>
            <div class="col-2 text-end">
              <a class="cursor-pointer" (click)="editMemberAddress()"><span class="text-black icon icon-pencil2"></span></a>
            </div>
          </div>
        </div>
      </div>
      <!--EO member details address view-->

      <!-- EDIT MEMBER DETAILS ADDRESS FORM -->
      <ng-container *ngIf="editingMemberAddress">
        <div class="shadow container mb-3">
          <div class="position-relative p-2">
            <div class="d-flex justify-content-between">
              <h4 class="text-secondary font-size-14 pt-2 mb-3">Address</h4>
              <a
                (click)="clearAddressForm(memberAddressForm)"
                class="text-primary heading-text-one mb-1 pt-2 font-size-14 cursor-pointer fw-bold"
                >Clear Form</a
              >
            </div>

            <form [formGroup]="memberAddressForm" class="font-size-12">
              <!--Street-->
              <div class="form-group">
                <label for="Street">
                  <span>Street</span>
                </label>
                <input
                  id="Street"
                  class="form-control"
                  name="Street"
                  type="text"
                  formControlName="Street"
                  [ngClass]="{
                    'is-invalid': hasSubmittedMemberAddress && !!memberAddressForm.controls.Street.errors
                  }"
                  required
                />
                <div class="invalid-feedback">Please enter a valid street.</div>
              </div>
              <!--City-->
              <div class="form-group">
                <label for="Town">
                  <span>City</span>
                </label>
                <input
                  id="Town"
                  class="form-control"
                  name="Town"
                  type="text"
                  formControlName="Town"
                  [ngClass]="{
                    'is-invalid': hasSubmittedMemberAddress && !!memberAddressForm.controls.Town.errors
                  }"
                  required
                />
                <div class="invalid-feedback">Please enter a valid city.</div>
              </div>
              <!--Country and region-->
              <fgb-country-and-regions
                [SeparateRow]="true"
                [Form]="memberAddressForm"
                [RegionLabel]="'State'"
                [HasSubmitted]="hasSubmittedMemberAddress"
              ></fgb-country-and-regions>
              <!--Zip code-->
              <div class="form-group">
                <label for="Postcode">
                  <span>Zip Code</span>
                </label>
                <input
                  id="Postcode"
                  class="form-control"
                  name="Postcode"
                  type="text"
                  formControlName="PostCode"
                  [ngClass]="{
                    'is-invalid': hasSubmittedMemberAddress && !!memberAddressForm.controls.PostCode.errors
                  }"
                  required
                />
                <div class="invalid-feedback">Please enter a valid zip code.</div>
              </div>
              <!--Phone-->
              <div class="form-group">
                <label for="Country">
                  <span>Phone Number</span>
                </label>
                <input
                  id="HomeNumber"
                  class="form-control"
                  name="HomeNumber"
                  type="text"
                  formControlName="HomeNumber"
                  [ngClass]="{
                    'is-invalid': hasSubmittedMemberAddress && !!memberAddressForm.controls.HomeNumber.errors
                  }"
                  required
                  onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                  minlength="8"
                  maxlength="11"
                />
                <div class="invalid-feedback">Please enter a valid phone number.</div>
              </div>
            </form>

            <div class="d-flex justify-content-between my-2 font-size-14">
              <button class="btn btn-primary fw-bold btn-cancel-save" (click)="cancelAndRebuildForm()">Cancel</button>
              <button class="btn btn-success fw-bold btn-cancel-save" (click)="saveMemberDetailAddress()">Save</button>
            </div>
          </div>
        </div>
      </ng-container>
      <!--EO edit member details address-->
      <fgb-errors></fgb-errors>
      <!--summary of items in basket-->
      <ng-container *ngIf="basketPointsTotal$ | async as totalPoints">
        <div class="container shadow py-2">
          <h4 class="text-secondary font-size-14">Summary</h4>
          <div class="row">
            <div class="col-6 fw-bold font-size-12"><span>Items</span>:</div>
            <div class="col-6 font-size-12 text-end">{{ totalPoints | FortressCurrencyPipe : 'PTS' }}</div>
          </div>
          <hr class="mx-3" />
          <div class="row">
            <div class="col-6 fw-bold font-size-12">Total Points:</div>
            <div class="col-6 font-size-12 text-end">{{ totalPoints | FortressCurrencyPipe : 'PTS' }}</div>
          </div>
        </div>
      </ng-container>

      <div>
        <p class="text-info font-size-12 my-2">*Your items will be shipped to your address</p>
      </div>

      <div class="px-0" *ngIf="items$ | async as items">
        <button class="btn btn-success fw-bold mt-2 w-100 font-size-14" (click)="payNow()" [disabled]="items.length == 0">
          Claim Now
        </button>
      </div>
    </div>
  </div>
</div>
