<div [class.row]="isList">
  <ng-container *ngIf="companies; else loadingRef">
    <ng-container *ngIf="companies.length; else noCompanies">
      <div *ngFor="let company of companies" [class.col-lg-4]="isList" [class.col-md-6]="isList">
        <fgb-company-list-item [company]="company"></fgb-company-list-item>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #noCompanies>
    No Companies Found
  </ng-template>
  <ng-template #loadingRef>
    <div class="loading-container">
      <fgb-loading></fgb-loading>
    </div>
  </ng-template>
</div>
