import { Component } from '@angular/core';
import { AuthService, config, MemberQuery, LoginType, LocaleService, PopUpNotificationService } from '@fgb/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
declare var gtag: any;
import { filter, tap } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';
@Component({
  selector: 'fgb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    public authService: AuthService,
    private translate: TranslateService,
    private memberQuery: MemberQuery,
    private localeService: LocaleService,
    private popUpNotificationService: PopUpNotificationService,
    private router: Router
  ) {
    this.translate.setDefaultLang('en-GB');

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + config.googleAnalyticsTag;
    document.head.prepend(script);

    const navigationEvent = this.router.events.pipe(filter((e) => e instanceof NavigationEnd));

    navigationEvent.subscribe((e) => {
      let event = e as NavigationEnd;
      gtag('config', config.googleAnalyticsTag, { page_path: event.urlAfterRedirects });
    });

    /**  redirect to maintenance page if it is enabled or API is failing (only for non admin users)  */
    if (authService.isAuthenticated()) {
      if (memberQuery.getLoginType() != LoginType.Admin) {
        this.authService.checkApiStatus();
      }

      firstValueFrom(this.localeService
        .getLocaleByLcid(parseInt(this.memberQuery.getUserContext().lcid))
        .pipe(
          tap((locale) => {
            this.translate.use(locale.CultureCode);
          })
        ));

      firstValueFrom(this.popUpNotificationService.fetchPopUpNotifications());
    }
  }
}
