<div class="points-summary-bar d-flex justify-content-between d-lg-none mt-2 py-3">
  <!-- Back Button -->
  <div class="back-btn text-danger cursor-pointer d-flex align-items-center d-lg-none" [routerLink]="[backButtonLink]">
    <span class="material-icons pe-2 fw-bold"> chevron_left </span>
    <span *ngIf="marketplaceItemGroup" class="font-size-14 fw-bold">Back</span>
    <span *ngIf="!marketplaceItemGroup" class="font-size-14 fw-bold">Back</span>
  </div>
  <!-- Points Bar -->
  <div class="justify-content-center flex-grow-1 flex-shrink-1 h-100">
    <fgb-points-summary></fgb-points-summary>
  </div>
</div>
