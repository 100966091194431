<div class="department-container overflow-hidden">
  <div class="row no-gutters">
    <div class="col-12 col-lg-6">
      <div class="department-image">
        <img class="w-100 rounded" src="{{ offer.ImageUrl | contentImage: '6' }}" alt="{{ offer.ImageUrl }}" />
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="department-content">
        <div class="department-title text-primary fw-bold text-start mt-3 mt-lg-0">
          {{ languageContent.Name }}
        </div>
        <div class="description-container">
          <fgb-short-long-description
            [description]="languageContent.Description"
            class="font-size-14"
          ></fgb-short-long-description>
        </div>
        <div class="d-flex mt-2">
          <div>
            <button
              class="
                btn-primary
                shop-now
                font-size-14
                text-center
                d-flex
                justify-content-center
                align-items-center
                text-white
                fw-bold
                me-4
                mb-4
                mt-2
                rounded
              "
              (click)="claimOffer(offer.OfferId)"
            >
              Redeem
            </button>
            <fgb-errors></fgb-errors>
          </div>
          <ng-container *ngIf="offer.TermsAndConditionsType == 0">
            <p></p>
          </ng-container>
          <ng-container *ngIf="offer.TermsAndConditionsType == 1">
            <p class="font-size-14 cursor-pointer text-primary mt-3" (click)="openOfferTermsModal(offer)">T&C's</p>
          </ng-container>
          <ng-container *ngIf="offer.TermsAndConditionsType == 2">
            <div>
              <a href="{{ offer.TermsAndConditions }}" target="_blank">
                <p class="font-size-14 mb-0 text-primary mt-3">T&C's</p>
              </a>
              <div class="font-size-16 text-success fw-bold">
                {{ successMessage }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
