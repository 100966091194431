import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyOfferQuery, Company, CompanyOfferModel, CompanyQuery, Department } from '@fgb/core';
import { Observable } from 'rxjs';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';

@Component({
  selector: 'fgb-offer-details-page',
  templateUrl: './offer-details-page.component.html',
  styleUrls: ['./offer-details-page.component.scss'],
})
export class OfferDetailsPageComponent implements OnInit {
  @Input() backButtonLink: string = '/offers';
  @Input() department: Department;
  currentCompany$: Observable<Company | undefined>;
  currentOffer$: Observable<CompanyOfferModel | undefined>;
  relatedOffers$: Observable<CompanyOfferModel[] | undefined>;
  otherOffers$: Observable<CompanyOfferModel[]>;

  customOptions: OwlOptions = {
    autoWidth: true,
    margin: OwlCarouselSettings.Margin,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: { items: 1 },
      540: { items: 2 },
      740: { items: 3 },
    },
  };

  constructor(
    private route: ActivatedRoute,
    private companyOfferQuery: CompanyOfferQuery,
    private companyQuery: CompanyQuery,
    private router: Router
  ) {}

  ngOnInit() {
    let offerId = +(this.route.snapshot.paramMap.get('offerId') || '0');
    this._updateOffer(offerId);
  }

  loadNewOffer(offerId: number) {
    this.router.navigateByUrl(`offers/details/${offerId}`);
    this._updateOffer(offerId);
  }

  _updateOffer(offerId: number) {
    if (!!offerId) {
      let newOffer = this.companyOfferQuery.getEntity(offerId);
      this.currentOffer$ = this.companyOfferQuery.selectEntity(offerId);
      this.relatedOffers$ = this.companyOfferQuery.selectRelatedOffers(offerId);

      if (newOffer) {
        this.currentCompany$ = this.companyQuery.selectEntity(newOffer.CompanyId);
        this.otherOffers$ = this.companyOfferQuery.selectOtherOffers(newOffer.CompanyId);
      }
    }
  }
}
