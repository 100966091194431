import { Component, Input, OnInit } from '@angular/core';
import {
  CardToken,
  CardTokenQuery,
  ProcessProviderId,
  RewardsWalletItem,
  RewardsWalletQuery,
  RewardsWalletService,
} from '@fgb/core';
import { firstValueFrom, Observable } from 'rxjs';

@Component({
  selector: 'fgb-wallet-page',
  templateUrl: './wallet-page.component.html',
  styleUrls: ['./wallet-page.component.scss'],
})
export class WalletPageComponent implements OnInit {
  @Input() marketplaceFilterTypes: ProcessProviderId[] = [0, 1, 2, 3, 4, 5];
  walletItemsUnclaimed$: Observable<RewardsWalletItem[]>;
  defaultCardToken$: Observable<CardToken>;
  constructor(
    private rewardWalletItemsService: RewardsWalletService,
    private rewardWalletQuery: RewardsWalletQuery,
    private cardTokenQuery: CardTokenQuery
  ) {}

  ngOnInit() {
    firstValueFrom(this.rewardWalletItemsService.fetchWalletItems());
    this.walletItemsUnclaimed$ = this.rewardWalletQuery.selectRewardWalletItemsByStatus(false);
    this.defaultCardToken$ = this.cardTokenQuery.selectDefaultCard();
  }
}
