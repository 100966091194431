import { Component, OnInit, Input } from '@angular/core';
import { Company } from '@fgb/core';

@Component({
  selector: 'fgb-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent implements OnInit {
  @Input() isList: boolean;
  @Input() companies: Company[];

  constructor() { }

  ngOnInit() {
  }
}
