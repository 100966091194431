<div class="announcements-container mb-3 rounded overflow-hidden border d-block">
  <ng-container *ngIf="!isComingSoon; else comingSoon">
    <ng-container *ngTemplateOutlet="default"></ng-container>
  </ng-container>

  <ng-template #default>
    <div class="announcement-item">
      <div class="announcement-image w-100">
        <img
          *ngIf="announcement.Image"
          class="w-100"
          src="{{ announcement.Image | contentImage : '6' }}"
          alt="{{ announcement.Image }}"
        />

        <img
          *ngIf="!announcement.Image"
          class="placeholder-image w-100"
          src="/assets/images/placeholders/announcements.jpg"
          alt=""
        />
      </div>
      <div class="announcements-text d-flex flex-column font-size-14 text-start">
        <h5 class="announcements-title mb-2 font-size-14 fw-bold">{{ announcement.Title }}</h5>
        <div class="description-text" [innerHTML]="announcement.Description"></div>
      </div>
      <div class="announcement-link">
        <a class="link" [href]="announcement.LinkUrl">{{ announcement?.LinkTitle }}</a>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #comingSoon>
  <div class="col-12 bg-announcements announcement-coming-soon">
    <label>{{ 'announcement.coming.soon.time.left' | translate }}</label>
    <fgbcl-countdown-v2
      [startDateTime]="announcement.RequestTime"
      [endDateTime]="announcement.StartDate"
      (complete)="isComingSoon = false"
    ></fgbcl-countdown-v2>
  </div>
  <div class="col-12 announcements-text text-start position-relative pt-4">
    <h3 class="announcements-title">{{ announcement.ComingSoonTitle }}</h3>
    <div class="announcements-info" [innerHTML]="announcement.ComingSoonDescription | safeHtml"></div>
    <ng-container *ngIf="announcement.LinkUrl">
      <a class="announcement-link" *ngIf="announcement.LinkType == 1" [routerLink]="announcement.LinkUrl">
        {{ announcement.LinkTitle }}
      </a>
      <a
        class="announcement-link"
        *ngIf="announcement.LinkType == 2"
        [href]="announcement.LinkUrl"
        [target]="isEnableTarget ? '_blank' : ''"
      >
        {{ announcement.LinkTitle }}
      </a>
    </ng-container>
  </div>
</ng-template>
