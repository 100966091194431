<ul class="nav nav-secondary mixed-loyalty-nav shadow mt-0" [ngClass]="{ 'mb-0': isWallet, 'mb-3': !isWallet }">
  <a class="nav-item btn" [routerLink]="['/rewards']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
    <span class="material-icons"> redeem </span>
    <label>{{ 'rewards.nav.marketplace' | translate }}</label>
  </a>
  <a
    class="nav-item btn d-none d-lg-flex"
    [routerLink]="['/rewards/wallet']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <span class="material-icons"> account_balance_wallet </span>
    <label>{{ 'rewards.nav.wallet' | translate }}</label>
  </a>
</ul>
