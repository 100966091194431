<ng-container *ngIf="announcements$ | async as announcements; else loadingRef">
  <div *ngIf="announcements.length > 0">
    <h5 class="text-start fw-bold text-capitalize mb-3">{{ 'home.page.announcement.title' | translate }}</h5>
    <div class="row">
      <ng-container *ngFor="let announcement of announcements">
        <div class="col-lg-4 col-md-6 mb-4">
          <fgb-announcement-content [announcement]="announcement"></fgb-announcement-content>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #loadingRef>
  <fgb-loading></fgb-loading>
</ng-template>
