<div class="d-block d-lg-none container my-3">
  <fgb-member-card></fgb-member-card>
</div>

<fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>

<div class="container pb-3">
  <div class="font-size-14 fw-bold text-center border border-disabled py-3 border-rounded">
    {{ 'rewards.sales.info' | translate }}
  </div>
</div>

<div class="container">
  <fgbcl-marketplace-list
    [isList]="true"
    [showSortBar]="false"
    [marketplaceItemTypes]="[3, 5]"
    [eventItemView]="true"
  ></fgbcl-marketplace-list>
</div>
