<div class="container mt-4">
  <div class="row">
    <div class="col-12 col-lg-6 order-lg-1 order-2 mt-4 mt-lg-0">
      <fgb-account-details></fgb-account-details>
    </div>
    <div class="col-12 col-lg-6 order-lg-2 order-1">
      <fgb-member-card showSeating="true"></fgb-member-card>
    </div>
  </div>
</div>
