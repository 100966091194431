<div class="login-title-section">
  <fgb-errors></fgb-errors>
</div>

<div class="form-group">
  <button (click)="loginTM()" class="btn text-white btn-block login-button w-100 font-size-14">
    <span>Login with</span>
    <span><img src="assets/images/ticketmaster.svg" class="tm-logo ps-2 mb-1" /></span>
  </button>
</div>
