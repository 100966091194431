import { Component, OnInit } from '@angular/core';
import { BalanceQuery, config } from '@fgb/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-balance-bar',
  templateUrl: './balance-bar.component.html',
  styleUrls: ['./balance-bar.component.scss'],
})
export class BalanceBarComponent implements OnInit {
  points$: Observable<number | undefined>;
  eCash$: Observable<number | undefined>;

  constructor(private balanceQuery: BalanceQuery) {}

  ngOnInit() {
    this.points$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
    this.eCash$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
  }
}
