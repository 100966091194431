<div class="footer text-white text-center">
  <div class="footer-quicklinks bg-black w-100 d-none d-lg-block"></div>
  <div class="footer-text bg-primary d-flex align-items-center justify-content-center">
    <div class="footer-content mt-3">
      <img class="footer-logo mb-2" src="assets/images/footer-cashapp.png" draggable="false" />
      <div class="text-white font-size-12 mt-2 text-center">{{ 'footer.copyright' | translate }}</div>
      <div class="text-white font-size-12 text-center">{{ 'footer.copyright2' | translate }}</div>
    </div>
  </div>
</div>
