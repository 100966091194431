<a routerLink="/rewards/bids/details/{{ auctionBid.Id }}">
  <div class="card loyalty border-0 shadow-sm">
    <div>
      <img
        class="card-img-top purchased-img-top"
        [src]="auctionBid.ImageUrl | contentImage: '6'"
        [alt]="auctionBid.ItemName"
        [useDefault]="true"
        [loyaltyType]="'auction'"
        draggable="false"
      />
      <div class="card-img-overlay bg-loyalty-gradient text-white px-3 pb-2 pt-4 rounded-0">
        <h5 class="mb-2">{{ auctionBid.ItemName }}</h5>
      </div>
    </div>
    <div class="cta bg-auction text-on-auction">
      <fgb-loyalty-icon *ngIf="!auctionBid.Status"></fgb-loyalty-icon>
      <span class="ms-1 fw-bold loyalty-status-text">{{ buttonText() }} </span>
    </div>
  </div>
</a>
