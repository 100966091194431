<div [ngClass]="{ shorten: shorten && descriptionTooLong }">
  <div [innerHTML]="description" class="description text-info"></div>
  <div class="overlay"></div>
</div>

<button
  *ngIf="descriptionTooLong"
  (click)="toggleReadMore()"
  class="btn btn-sm btn-link read-more fw-bold p-0 text-primary"
>
  {{ this.shorten ? 'Read more' : 'Read less' }}
</button>
