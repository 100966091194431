import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { MobilePassStore, MobilePassState } from './mobile-pass.store';

@Injectable({ providedIn: 'root' })
export class MobilePassQuery extends QueryEntity<MobilePassState> {

  constructor(protected store: MobilePassStore) {
    super(store);
  }

}
