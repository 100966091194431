<!--Scorecards-->
<fgb-badges-nav></fgb-badges-nav>
<div class="bg-scorecard mt-3">
  <fgbcl-scorecard-list-view [icon]="'chevron_right'" [phaseThree]="true"></fgbcl-scorecard-list-view>
</div>

<ng-container *ngIf="featuredOffers$ | async as featuredOffers">
  <ng-container *ngIf="!!featuredOffers.length">
    <div class="container mt-2 offers">
      <div class="row">
        <div class="col-12">
          <h5 class="text-capitalize font-page-heading mt-4">{{ 'offers.page.subheading' | translate }}</h5>
          <div class="row">
            <ng-container *ngFor="let offer of featuredOffers">
              <div class="col-12 col-lg-4">
                <fgb-offers-list-item (navigateToOffer)="navigateToOffer($event)" [offer]="offer"></fgb-offers-list-item>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
