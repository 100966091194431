<div class="next-game-title mb-2">NEXT GAME</div>
<div class="text-center">
  <div class="d-flex justify-content-center mt-1">
    <div class="d-flex flex-column">
      <span class="countdown-timer text-primary">{{ days }}</span>
      <span class="font-size-12 text-info">DD</span>
    </div>
    <span class="text-secondary mx-3 countdown-timer">:</span>
    <div class="d-flex flex-column">
      <span class="countdown-timer text-primary">{{ hours }}</span>
      <span class="font-size-12 text-info">HH</span>
    </div>
    <span class="text-secondary countdown-timer mx-3">:</span>

    <div class="d-flex flex-column">
      <span class="countdown-timer text-primary countdown-timer">{{ minutes }}</span>
      <span class="font-size-12 text-info">MM</span>
    </div>
  </div>
</div>
