import { Component, Input, Signal } from '@angular/core';
import { NotificationCenterQuery, NotificationCenterService } from '@fgb/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationModule } from '@fgb/portal-component-library/src/lib/club/notification';
import { CommonModule } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'fgb-notification-button',
  templateUrl: './notification-button.component.html',
  styleUrls: ['./notification-button.component.scss'],
  standalone: true,
  imports: [ NgbDropdownModule, TranslateModule, NotificationModule, CommonModule ]
})
export class NotificationButton{
  @Input() size: number = 20;
  unseenCount: Signal<number | undefined> = toSignal(this.notificationQuery.unseenNotificationsCount$);
  isOpen: boolean;

  constructor(
    private notificationService: NotificationCenterService,
    private notificationQuery: NotificationCenterQuery) { }

  toggleNotificationCenter(open: boolean) {
    this.isOpen = open;
    this.notificationService.updateIsOpen(open);
  }

}
