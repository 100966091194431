<div class="row">
  <div class="col-12">
    <h5 class="mt-3 mb-2 fw-bold header-text">{{ 'rewards.nav.auctions' | translate }}</h5>
  </div>
</div>

<div class="row" *ngIf="auctionBids$ | async as auctionBids">
  <ng-container *ngIf="auctionBids.length">
    <a *ngFor="let item of auctionBids" routerLink="/rewards/bids/details/{{ item.Id }}" class="col-md-6 col-lg-4">
      <div class="loyalty-item-container">
        <fgb-auction-bid-list-item [auctionBid]="item"></fgb-auction-bid-list-item>
      </div>
    </a>
  </ng-container>
</div>
