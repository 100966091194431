import { NgModule } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UserModule } from '../user/user.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { InlineSVGModule } from 'ng-inline-svg-2';
import { LayoutModule } from '../layouts/layout.module';
import { WorksheetListComponent } from './components/worksheet-list/worksheet-list.component';
import { WorksheetItemComponent } from './components/worksheet-item/worksheet-item.component';

@NgModule({
  declarations: [WorksheetListComponent, WorksheetItemComponent],
  exports: [WorksheetListComponent, WorksheetItemComponent],
  imports: [
    RouterModule,
    NgbModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CarouselModule,
    BrowserAnimationsModule,
    SharedModule,
    InlineSVGModule.forRoot(),
    UserModule,
    LayoutModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: function HttpLoaderFactory(http: HttpClient) {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json');
        },
        deps: [HttpClient],
      },
    }),
  ],
})
export class LoyaltyModule {}
