import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ContactService, Category, Contact } from '@fgb/core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'fgb-contact-us-page',
  templateUrl: './contact-us-page.component.html',
  styleUrls: ['./contact-us-page.component.scss'],
})
export class ContactUsPageComponent implements OnInit, OnDestroy {
  contactUsForm: FormGroup;
  categories$: Observable<Category[]>;
  submitted = false;
  sentSuccessfully: boolean = false;

  private readonly _subscription = new Subscription();

  constructor(private formBuilder: FormBuilder, private contactService: ContactService) {}

  get f() {
    return this.contactUsForm.controls;
  }

  ngOnInit() {
    this.categories$ = this.contactService.getCategories();

    this.initForm();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  async submit(message: Contact, isValid: boolean) {
    this.submitted = true;
    if (isValid) {
      await this.contactService.sendMessage(message);
      this.sentSuccessfully = true;
    }
  }

  public initForm(): void {
    this.contactUsForm = this.formBuilder.group({
      category: [''],
      message: ['', [Validators.required, this.noWhitespaceValidator]],
    });

    this.watchMessage(this.contactUsForm);
    this.watchCategories(this.contactUsForm);
  }

  public watchCategories(fg: FormGroup): void {
    this._subscription.add(
      this.categories$.pipe(take(1)).subscribe((cats) => {
        if (cats.length) {
          fg.controls.category.setValue(cats[0].Name);
        }
      })
    );
  }

  public watchMessage(fg: FormGroup): void {
    this._subscription.add(
      fg.get('message')?.valueChanges.subscribe((content: string) => {
        this.sentSuccessfully = false;
      })
    );
  }

  public noWhitespaceValidator(control: FormControl) {
    if (control.value === '') return;
    return control.value.trim().length ? null : { whitespace: true };
  }
}
