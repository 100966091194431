<div class="my-4">
  <div class="survey-answer">
    <div class="survey-answer-container justify-content-center">
      <div class="survey-answer-item w-100" *ngFor="let answer of selectedAnswers">
        <div class="d-flex flex-row">
          <img class="image-container icon" src="{{ answer.ImageURL | contentImage: '1' }}" alt="{{ answer.ImageURL }}" />
          <div class="answer-text-landscape text-center text-white bg-secondary">
            <span>{{ answer.Title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center fw-bold font-size-16 text-secondary mt-4">Thank you for submitting your selection</div>
  <div class="text-center text-secondary font-size-14 mb-lg-5 mb-3">
    Please come back after the conclusion of this predictor to view your results
  </div>
</div>
