import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PartnerService, ErrorStateService } from '@fgb/core';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'fgb-partner-accept-form',
  templateUrl: './partner-accept-form.component.html',
  styleUrls: ['./partner-accept-form.component.scss'],
})
export class PartnerAcceptFormComponent implements OnInit {
  constructor(private fb: FormBuilder, private partnerService: PartnerService, private errorService: ErrorStateService) {}
  acceptForm: FormGroup;
  @Output() close = new EventEmitter();

  ngOnInit() {
    this.acceptForm = this.fb.group({
      code: ['', Validators.required],
      secretCode: ['', Validators.required],
    });
  }

  submit() {
    const code: string = this.acceptForm.value.code;
    const secretCode: string = this.acceptForm.value.secretCode;
    firstValueFrom(this.partnerService
      .confirmPartner(secretCode, code))
      .catch(t => {
        this.errorService.clearErrors();
        this.errorService.addError('Please enter valid partner credentials');
      });
    this.close.emit();
  }
}
