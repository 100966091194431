import { Component, OnInit, Input } from '@angular/core';
import { AuctionBidDetails, AuctionBidStatus } from '@fgb/core';

@Component({
  selector: 'fgb-auction-bid-list-item',
  templateUrl: './auction-bid-list-item.component.html',
  styleUrls: ['./auction-bid-list-item.component.scss'],
})
export class AuctionBidListItemComponent implements OnInit {
  constructor() {}
  @Input() auctionBid: AuctionBidDetails;

  ngOnInit() {}

  buttonText() {
    const bid = this.auctionBid;
    if (bid.Status === AuctionBidStatus.Active) {
      return bid.ItemWon ? 'Winning' : 'Outbid';
    } else {
      if (bid.ItemWon) {
        return +bid.RedeemStatus ? 'Redeemed' : 'Redeemed';
      } else {
        return 'Lost';
      }
    }
  }
}
