<ng-container *ngIf="partners$ | async as partners">
  <div class="card mt-3 mt-lg-0" [formGroup]="transferPointsForm">
    <div class="card-body p-3 points-transfer">
      <div class="points-transfer-header mt-2">
        <h4 class="text-center">Who would you like to transfer points</h4>
      </div>
      <div class="btn-group col-lg-6 offset-lg-3 mt-2 mb-2">
        <button
          *ngFor="let type of PointsTransferTypes"
          type="button"
          class="btn"
          [ngClass]="{
            'btn-primary': selectedPointsTransferType === type,
            'btn-muted': selectedPointsTransferType !== type
          }"
          (click)="SelectPointsTransferType(type)"
        >
          {{ buttonNames[type] }}
        </button>
      </div>

      <div *ngIf="selectedPointsTransferType === 0 && partners.length > 0">
        <div class="points-transfer-header mt-4 mb-4">
          <h4 class="text-center">Choose your recipient</h4>
        </div>
        <ng-container *ngIf="partners.length > 1">
          <owl-carousel-o [options]="customOptions">
            <ng-container *ngFor="let item of partners">
              <ng-template carouselSlide>
                <div
                  class="card points-transfer-carousel-item pt-3 d-flex flex-column align-items-center"
                  (click)="selectPartner(item)"
                  [ngClass]="{
                    'selected-item text-white': item === selectedPartner
                  }"
                >
                  <div
                    class="icon svg mb-3 points-transfer-icon"
                    [inlineSVG]="'assets/images/icons/filled-account-icon.svg'"
                  ></div>
                  <p class="mb-2">{{ item.FullName }}</p>
                  <p class="account-text"><span class="fw-bold">Acc No</span> {{ item.ExternalRefNumber }}</p>
                </div>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </ng-container>
      </div>

      <!--Hidden input when picking a partner-->
      <input hidden type="number" formControlName="PartnerAccountNumber" />

      <div *ngIf="selectedPointsTransferType === 0 && partners.length === 0">
        <p class="text-center">You do not have any Partners to select</p>
      </div>

      <div *ngIf="selectedPointsTransferType === 1" class="d-flex flex-column align-items-center">
        <div class="points-transfer-header mt-4 mb-2 text-center">
          <h4>Please type the Account number of your recipient</h4>
        </div>
        <div class="text-center mb-2">
          <p class="fw-bold">Account Number</p>
          <input
            class="form-control text-center"
            id="inputAccountNumber"
            type="text"
            [ngClass]="{ 'is-invalid': !!transferPointsForm.controls.PartnerAccountNumber.errors }"
            formControlName="PartnerAccountNumber"
          />
          <div class="invalid-feedback">Please enter an account number.</div>
        </div>
        <fgb-errors></fgb-errors>
      </div>

      <hr class="w-50 mx-auto" />

      <div
        class="mt-4 d-flex flex-column align-items-center"
        [ngClass]="{
          'greyed-out': showPointsCounter()
        }"
      >
        <div class="points-transfer-header text-center">
          <h4>Please select amount of points you want to transfer</h4>
        </div>
        <div>
          <div class="mb-3 mb-lg-0 amount-counter">
            <fgb-number-input
              [isActive]="true"
              [minValue]="1"
              [maxValue]="balance"
              [incrementValue]="1"
              theme="auction"
              [(amount)]="amount"
              [ngClass]="{ 'is-invalid': !!transferPointsForm.controls.Amount.errors }"
              [minMessage]="'1 point is the minimum'"
              [maxMessage]="'You do not have enough points'"
            >
            </fgb-number-input>
          </div>
          <div class="mt-3 d-flex justify-content-around points-transfer-button-container">
            <button [ngClass]="{ 'greyed-out': balance < 100 }" (click)="addPoints(100)">100 Pts</button>

            <button [ngClass]="{ 'greyed-out': balance < 500 }" (click)="addPoints(500)">500 Pts</button>
            <button [ngClass]="{ 'greyed-out': balance < 1000 }" (click)="addPoints(1000)">1,000 Pts</button>
            <button [ngClass]="{ 'greyed-out': balance < 2000 }" (click)="addPoints(2000)">2,000 Pts</button>
          </div>

          <div class="text-center mt-4">
            <button (click)="submitStep()" class="btn btn-primary send-button" [disabled]="amount < 1 || amount > balance">
              Send
            </button>
          </div>

          <!-- Confirm modal -->
          <ng-template #confirmModal>
            <div class="points-transfer-modal">
              <div class="text-center">
                <p>
                  <b
                    >Are you sure you want to transfer
                    <span class="text-success">{{ transferPointsForm.controls.Amount.value }}</span> to
                    <span class="text-danger">{{ transferPointsForm.controls.PartnerAccountNumber.value }}</span
                    >?</b
                  >
                </p>
              </div>
              <div class="text-center mb-5">
                <button type="button" class="btn btn-danger fw-bold mb-5 float-start" (click)="closeModal()">
                  Cancel
                </button>
                <button type="button" class="btn btn-success fw-bold mb-5 float-end" (click)="transferPoints()">
                  Confirm
                </button>
              </div>
            </div>
          </ng-template>

          <!-- Success modal -->
          <ng-template #successModal>
            <div class="points-transfer-modal">
              <div class="text-center">
                <p class="text-success"><b>Successfully Processed</b></p>
              </div>
              <div class="text-center">
                <button type="button" class="btn btn-success fw-bold mb-5" (click)="closeModal()">Ok</button>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-container>
