import { Component, OnInit } from '@angular/core';
import { VoucherService, VoucherRedeemModel, BalanceService, config } from '@fgb/core';
import { take } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'fgb-vouchers',
  templateUrl: './vouchers.component.html',
  styleUrls: ['./vouchers.component.scss']
})
export class VouchersComponent implements OnInit {
  voucherCode: string;
  redemptionResult: string;

  private successMessage: string = 'Code successfully redeemed.';

  constructor(
    private voucherService: VoucherService,
    private balanceService: BalanceService
  ) {}

  ngOnInit() {}

  redeemVoucher() {
    if (!this.voucherCode) {
      this.redemptionResult = 'Redemption code is empty';
      return;
    }

    let voucher = new VoucherRedeemModel();
    voucher.VoucherCode = this.voucherCode;

    this.redemptionResult = '';
    firstValueFrom(this.voucherService
      .redeemVoucher(voucher))
      .then(item => {
        this.balanceService.fetchBalance(config.purseConfig.virtualLoyalty).pipe(take(1)).subscribe();
        this.redemptionResult = this.successMessage;
      })
  }
}
