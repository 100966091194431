<div class="d-flex align-items-center justify-content-center">
  <ng-container *ngIf="mobilePassUrl$ | async as mobilePass; else urlNotFound">
    <!--Desktop-->
    <ng-container *ngIf="deviceDetector.isDesktop()">
      <button class="btn btn-primary rounded" (click)="downloadPassDesktop(mobilePass.url)">
        {{ 'mobile.pass.download' | translate }}
      </button>
    </ng-container>
    <!--Mobile-->
    <ng-container *ngIf="deviceDetector.isMobile()">
      <!--ANDROID-->
      <ng-container *ngIf="deviceDetector.os.toUpperCase() === 'ANDROID'">
        <a [href]="mobilePass.url" target="_blank">
          <button class="btn btn-android">
            <img src="assets/images/passes/google.svg" height="40" />
          </button>
        </a>
      </ng-container>
      <!--IOS-->
      <ng-container *ngIf="deviceDetector.os.toUpperCase() === 'IOS'">
        <a [href]="mobilePass.url" target="_blank">
          <button class="btn">
            <img src="assets/images/passes/apple.svg" />
          </button>
        </a>
      </ng-container>
    </ng-container>
  </ng-container>

  <!--Loading pass-->
  <ng-template #urlNotFound>
    <fgb-loading></fgb-loading>
  </ng-template>
</div>
