<div class="detail-page">
  <div class="container">
    <div class="d-none d-lg-block">
      <div class="mt-3 back-btn text-danger cursor-pointer d-flex align-items-center" [routerLink]="'/rewards'">
        <span class="material-icons pe-2 fw-bold"> chevron_left </span>
        <span class="font-size-14 fw-bold">Back</span>
      </div>
    </div>

    <!-- Points Summary -->
    <fgb-points-summary-bar [backButtonLink]="backLink" [marketplaceItemGroup]="marketplaceItemGroup"></fgb-points-summary-bar>
  </div>

  <fgbcl-marketplace-details [itemId]="id" [actionLabels]="actionLabels"></fgbcl-marketplace-details>
</div>
