import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fgb-mixed-loyalty-nav',
  templateUrl: './mixed-loyalty-nav.component.html',
  styleUrls: ['./mixed-loyalty-nav.component.scss']
})
export class MixedLoyaltyNavComponent implements OnInit {
  @Input() isWallet:boolean=false;
  constructor() {}

  ngOnInit() {}
}
