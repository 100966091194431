<div class="card rounded">
  <div class="row">
    <div class="col-12">
      <label class="mb-0">{{ 'account.details.points' | translate }}</label>
      <div>
        <span class="icon icon-trophy-1 fw-bold font-size-14"></span>
        <span class="ps-2" *ngIf="loyaltyPointsCard$">{{ loyaltyPointsCard$ | async | FortressCurrencyPipe: 'PTS' }}</span>
      </div>
    </div>
  </div>
  <div *ngIf="!allowEditing">
    <div *ngIf="member$ | async as member">
      <div>
        <label class="mb-0 personal-info">{{ 'account.details.home.address' | translate }}</label>
        <div class="d-flex bg-muted p-2 m-0 mt-1 rounded">
          <span class="icon icon-location-3 d-inline-block fw-bold"></span>
          <span class="ms-3 d-inline-block text-info">
            <div>{{ member.AddressName }}</div>
            <div>{{ member.Street }}</div>
            <div>{{ member.Town }}</div>
            <div>{{ member.Country }}</div>
            <div>{{ member.PostCode }}</div>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="allowEditing">
    <fgb-edit-account-details></fgb-edit-account-details>
  </div>
</div>
<ng-template #loadingRef>
  <fgb-loading></fgb-loading>
</ng-template>
