<div class="form-group">
  <div class="quantity-select-container">
    <div class="quantity">
      <!-- Minus Button -->
      <button
        class="btn btn-{{ theme || 'primary' }} decrement-button"
        (click)="decrement()"
        [disabled]="!isActive || amount <= minValue"
      ></button>
      <!-- Input Field -->
      <input
        id="amountInput"
        type="number"
        [ngModel]="amount"
        (ngModelChange)="amountSubject.next($event)"
        class="form-control bid-ammount-input text-center font-size-14"
        [disabled]="!isActive"
        min="{{ minValue }}"
        max="{{ maxValue }}"
      />
      <!-- Plus Button -->
      <button
        class="btn btn-{{ theme || 'primary' }} increment-button"
        (click)="increment()"
        [disabled]="!isActive || amount >= maxValue"
      ></button>
    </div>
  </div>
</div>

<!-- Errors-->
<div class="errors">
  <p class="error" id="minMessage" *ngIf="showMinMessage">* {{ minMessage }}</p>
  <p class="error" id="maxMessage" *ngIf="showMaxMessage">* {{ maxMessage }}</p>
</div>
