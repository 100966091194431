<div class="text-black d-none d-lg-flex">
  <a
    class="d-flex align-items-center account-btn text-black bg-white p-0 nav-item-fixed-height"
    (click)="connectToTM()"
    ngbDropdownItem
  >
    <span class="fw-bold material-icons me-2 font-size-22"> book_online </span>
    <div class="fw-bold dropdown-link text-start">
      <div class="font-size-14">{{ 'nav.manage.tickets' | translate }}</div>
    </div>
  </a>
</div>
