import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {
  CompanyOfferLanguageModel,
  CompanyOfferModel,
  CompanyOfferService,
  CompanyOfferType,
  ErrorStateService,
} from '@fgb/core';

@Component({
  selector: 'fgb-offers-list-item',
  templateUrl: './offers-list-item.component.html',
  styleUrls: ['./offers-list-item.component.scss'],
})
export class OffersListItemComponent implements OnInit {
  @Input() ignoreLink: boolean = false;
  @Input() offer: CompanyOfferModel;
  languageContent: CompanyOfferLanguageModel;
  successMessage: string;
  companyOfferType = CompanyOfferType;

  constructor(private companyOfferService: CompanyOfferService, private errorService: ErrorStateService) {}

  ngOnInit() {
    this._updateLanguageContent();
  }

  ngOnChanges() {
    this._updateLanguageContent();
  }

  _updateLanguageContent() {
    let currentLcid = localStorage.getItem('lcid');

    if (!!currentLcid) {
      this.languageContent = !!this.offer.Content.filter((language) => language.LCID == parseInt(currentLcid as string))[0]
        ? this.offer.Content.filter((language) => language.LCID == parseInt(currentLcid as string))[0]
        : this.offer.Content[0];
    } else {
      this.languageContent = this.offer.Content[0];
    }
  }

  async claimOffer(offerId: number) {
    await this.companyOfferService
      .claimOffer(offerId)
      .then((response) => {
        if (response.ErrorNumber > 0) {
          this.errorService.clearErrors();
          this.errorService.addError(response.ErrorDescription);
        } else if (response.RedirectUrl != null) {
          window.location.href = response.RedirectUrl;
        } else {
          this.successMessage = response.ErrorDescription;
        }
      })
      .catch((error) => {
        this.errorService.clearErrors();
        this.errorService.addError(error.Message);
      });
  }
}
