import { Component, OnInit, Input } from '@angular/core';
import { DepartmentService, Department } from '@fgb/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'fgb-affiliate-item',
  templateUrl: './affiliate-item.component.html',
  styleUrls: ['./affiliate-item.component.scss'],
})
export class AffiliateItemComponent implements OnInit {
  @Input() department: Department;
  @Input() ignoreLink: boolean = false;

  constructor(private departmentService: DepartmentService, private modalService: NgbModal) {}

  readMoreShowing: boolean = false;
  shouldShowReadMore: boolean = true;
  private readMoreDescriptionLength: number = 100;

  ngOnInit() {
    this.shouldShowReadMore = this.department && this.department.Description.length > this.readMoreDescriptionLength;
  }

  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  close() {
    this.modalService.dismissAll();
  }

  showReadMore = () => (this.readMoreShowing = true);
  hideReadMore = () => (this.readMoreShowing = false);

  processAffiliate() {
    firstValueFrom(this.departmentService
      .processAffiliate(this.department.CompanyId, this.department.DepartmentId))
      .then((r) => {
        window.location.href = r;
      });
  }
}
