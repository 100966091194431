import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DepartmentQuery } from '@fgb/core';
import { CompanyQuery } from '@fgb/core';
import { Observable } from 'rxjs';
import { Department } from '@fgb/core';
import { Company } from '@fgb/core';

@Component({
  selector: 'fgb-company-departments-page',
  templateUrl: './company-departments-page.component.html',
  styleUrls: ['./company-departments-page.component.scss'],
})
export class CompanyDepartmentsPageComponent implements OnInit {
  @Input() backButtonLink: string = '/company';
  @Input() department: Department;
  departments$: Observable<Department[]>;
  company$: Observable<Company | undefined>;

  constructor(private route: ActivatedRoute, private departmentQuery: DepartmentQuery, private companyQuery: CompanyQuery) {}

  ngOnInit() {
    let companyId = +(this.route.snapshot.paramMap.get('companyId') || '0');
    if (companyId) {
      this.departments$ = this.departmentQuery.selectDepartments(companyId);
      this.company$ = this.companyQuery.selectCompany(companyId);
    }
  }
}
