<ul class="nav nav-secondary account-details-nav">
  <a class="nav-item" [routerLink]="['/earn']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
    <span class="material-icons font-size-24"> money </span>
    <label>How to Earn</label>
  </a>
  <a class="nav-item" [routerLink]="['/activity']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
    <span class="material-icons font-size-24"> history </span>
    <label>Activity</label>
  </a>
</ul>
