<div class="banner">
  <h1>Test population</h1>
</div>

<div class="container text-center">

    <div class="row fixed-height-cards">
        <div class="col-md-4">
          <h5>Next Game</h5>
          <div class="card">
            <div class="card-body">
              <fgb-next-game></fgb-next-game>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <h5>Voucher Code</h5>
          <div class="card">
            <div class="card-header bg-primary text-on-primary">
              Enter Redemption Code To Earn Prizes
            </div>
            <div class="card-body">
              <fgb-vouchers></fgb-vouchers>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <h5>Earn Points</h5>
          <a routerLink="/earn">
            <div class="card">
              <div class="card-body">
                <h3>HOW TO EARN POINTS</h3>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h5>Announcements</h5>
        </div>
      </div>
      <fgb-announcements></fgb-announcements>
</div>
