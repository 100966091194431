import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { WorksheetQuery, WorksheetService } from '@fgb/core';
import { Router } from '@angular/router';
import { firstValueFrom, Subscription } from 'rxjs';

@Component({
  selector: 'fgb-worksheet-list',
  templateUrl: './worksheet-list.component.html',
  styleUrls: ['./worksheet-list.component.scss'],
})
export class WorksheetListComponent implements OnInit, OnDestroy {
  @Input() isList: boolean = true;
  @Output() hasVideo: EventEmitter<boolean> = new EventEmitter(false);
  worksheets$ = this.worksheetQuery.getWorksheetByRoute(this.router.url.replace('/', ''));
  supscriptions: Subscription;
  constructor(private worksheetService: WorksheetService, private worksheetQuery: WorksheetQuery, private router: Router) {}

  ngOnInit() {
    if (!this.worksheetQuery.hasRoute(this.router.url.replace('/', ''))) {
      firstValueFrom(this.worksheetService.fetchWorksheetByRoute(this.router.url.replace('/', '')));
    }
    this.supscriptions = this.worksheets$.subscribe((value) => {
      let hasValue = value.find((x) => x.Items.length);
      this.hasVideo.emit(!!hasValue);
    });
  }

  ngOnDestroy(): void {
    this.supscriptions.unsubscribe();
  }
}
