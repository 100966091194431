<ng-container *ngIf="partners$ | async as partners">
  <ng-container *ngIf="partners.length > 0">
    <h5 class="text-center mt-4">Current Partners</h5>
    <div class="card card-primary my-2 bg-white">
      <div class="card-body">
        <div class="row text-start">
          <div class="col-4 d-none d-lg-block">
            <label class="fw-bold">Full Name</label>
          </div>
          <div class="col-4 d-none d-lg-block">
            <label class="fw-bold">Email</label>
          </div>
          <div class="col-8 d-block d-lg-none">
            <label class="fw-bold">Partner Details</label>
          </div>
        </div>
        <div *ngFor="let partner of partners" class="row text-start">
          <div class="col-4 mt-4 d-none d-lg-block">
            <div class="partner-info">
              <div>{{ partner.FullName }}</div>
            </div>
          </div>
          <div class="col-4 mt-4 d-none d-lg-block">
            <div class="partner-info">
              <div>{{ partner.Email }}</div>
            </div>
          </div>
          <div class="col-8 d-block d-lg-none">
            <div class="partner-info mt-3">
              <div>{{ partner.FullName }}</div>
              <div class="text-primary">{{ partner.Email }}</div>
            </div>
          </div>
          <div class="col-3 col-lg-2 offset-1 offset-lg-2 d-flex align-items-center mt-3">
            <button
              class="btn btn-danger w-100 flex-grow-0 ms-auto d-flex align-items-center justify-content-center"
              confirmModal
              [confirmContent]="confirmContent"
              (confirm)="delete(partner.RelationshipId)"
            >
              <span class="icon icon-trash fw-bold"></span>
            </button>
          </div>
        </div>
        <ng-template #confirmContent>
          <div>
            <b>Are you sure you want to delete this partner?</b>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-container>
</ng-container>
