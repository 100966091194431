<div class="container">
  <div class="contact-us-box rounded p-3 bg-white">
    <div class="col-12 col-md-8 offset-lg-2">
      <form [formGroup]="contactUsForm" (ngSubmit)="submit(contactUsForm.value, contactUsForm.valid)">
        <div class="contact-title-text fw-bold mt-2 mb-1 font-size-12">
          {{ 'contact.us.category' | translate }}
        </div>
        <div>
          <div class="dropdown">
            <select
              class="contact-us-dropdown w-100 text-start rounded p-2 bg-primary text-white font-size-14"
              formControlName="category"
            >
              <option *ngFor="let category of categories$ | async" [value]="category.Value">
                {{ category.Value }}
              </option>
            </select>
          </div>
        </div>
        <div>
          <div class="contact-title-text fw-bold mt-2 mb-1 font-size-12">
            {{ 'contact.us.message' | translate }}
          </div>
          <textarea class="input-group h-200 rounded" formControlName="message" required></textarea>
          <div *ngIf="submitted && f.message.errors && !sentSuccessfully" class="alert alert-danger">
            <div *ngIf="f.message.errors.required">
              {{ 'contact.us.message.error' | translate }}
            </div>
            <div *ngIf="f.message.errors.whitespace">
              {{ 'contact.us.message.whitespace' | translate }}
            </div>
          </div>
        </div>
        <button
          class="send-button font-size-14 d-block ms-auto me-auto rounded mt-4 bg-primary fw-bold text-white mb-2 border-0"
          type="submit"
          [disabled]="sentSuccessfully"
        >
          {{ 'contact.us.submit' | translate }}
        </button>
        <div class="success-message text-center" *ngIf="sentSuccessfully">
          {{ 'contact.us.submit.success' | translate }}
        </div>
      </form>
    </div>
  </div>
</div>
