<div class="d-none d-lg-block">
  <div class="banner mb-3">
    <h1>Raffles</h1>
  </div>

  <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
</div>

<div class="container my-5" *ngIf="lottoItem">
  <div [innerHTML]="lottoItem.TermsAndConditions"></div>
</div>
