<div class="d-none d-lg-block">
  <a class="company-container mb-3 rounded shadow d-block">
    <div class="company-item">
      <div class="company-info">
        <div class="company-image d-flex align-items-left">
          <img src="{{ company.ImageUrl | contentImage: '1' }}" alt="{{ company.ImageUrl }}" />
        </div>
        <div
          class="company-description position-relative mt-3 mb-0 text-start font-size-12 text-black overflow-hidden"
          [innerHTML]="company.Description"
        ></div>
        <div class="mb-3 text-start see-more-link">
          <a class="text-primary fw-bold font-size-12" [owlRouterLink]="'/company/' + company.CompanyId"> See more</a>
        </div>
      </div>
      <div class="view-offer">
        <a
          class="btn-primary button d-flex justify-content-center align-items-center text-white fw-bold rounded-bottom"
          [owlRouterLink]="'/company/' + company.CompanyId"
          [stopLink]="ignoreLink"
        >
          <span class="icon icon icon-cart-1"></span>
          <span class="view-offer-text ms-1 font-size-14 fw-bold">Shop Now</span>
        </a>
      </div>
    </div>
  </a>
</div>

<div class="d-block d-lg-none">
  <a class="company-container-mobile shadow rounded overflow-hidden d-block">
    <div class="company-info">
      <div class="company-image d-flex align-items-left">
        <img src="{{ company.ImageUrl | contentImage: '1' }}" alt="{{ company.ImageUrl }}" />
      </div>

      <div
        class="company-description-mobile font-size-12 text-black d-flex text-start overflow-hidden mt-3"
        [innerHTML]="company.Description"
      ></div>
      <div class="mb-4 text-start see-more-link">
        <a class="text-primary fw-bold font-size-12" [owlRouterLink]="'/company/' + company.CompanyId"> See more</a>
      </div>
    </div>

    <div
      class="view-offer-box-mobile p-1 bg-primary text-white w-100 d-flex justify-content-center align-items-center"
      [owlRouterLink]="'/company/' + company.CompanyId"
      [stopLink]="ignoreLink"
    >
      <span class="icon icon icon-cart-1"></span>
      <span class="view-offer-text ms-1 font-size-14 fw-bold">Shop Now</span>
    </div>
  </a>
</div>
