import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopupComponent } from './components/topup/topup.component';
import { CardTokenListComponent } from './components/card-token-list/card-token-list.component';
import { AddNewCardComponent } from './components/add-new-card/add-new-card.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from 'src/app/shared/shared.module';
import { AutotopupComponent } from './components/autotopup/autotopup.component';
import { EcashControlsComponent } from './components/ecash-controls/ecash-controls.component';
import { TopUpTicketComponent } from './components/top-up-ticket/top-up-ticket.component';
import { EcashMemberCardComponent } from './components/ecash-member-card/ecash-member-card.component';
import { UserModule } from '../user/user.module';
import { CardTokenComponent } from './components/card-token/card-token.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NonPartnerBonusTransferComponent } from './components/non-partner-bonus-transfer/non-partner-bonus-transfer.component';
import { PartnerBonusTransferComponent } from './components/partner-bonus-transfer/partner-bonus-transfer.component';
import { ConfirmModalModule } from '@fgb/portal-component-library/src/lib/shared/confirm-modal';
@NgModule({
  declarations: [
    AutotopupComponent,
    TopupComponent,
    CardTokenComponent,
    CardTokenListComponent,
    AddNewCardComponent,
    TopUpTicketComponent,
    EcashControlsComponent,
    EcashMemberCardComponent,
    NonPartnerBonusTransferComponent,
    PartnerBonusTransferComponent,
  ],
  imports: [
    NgbModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    UserModule,
    ConfirmModalModule,
    CarouselModule,
    InlineSVGModule.forRoot(),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: function HttpLoaderFactory(http: HttpClient) {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json');
        },
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    AutotopupComponent,
    TopupComponent,
    CardTokenComponent,
    CardTokenListComponent,
    AddNewCardComponent,
    TopUpTicketComponent,
    EcashControlsComponent,
    EcashMemberCardComponent,
    NonPartnerBonusTransferComponent,
    PartnerBonusTransferComponent
  ],
})
export class EcashModule {}
