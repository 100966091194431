<div class="banner">
  <h1>Affiliates</h1>
</div>
<div class="d-block d-lg-none">
  <a [routerLink]="[backButtonLink]" class="btn btn-primary mt-3 mx-2 d-flex align-items-center py-3 h-100 fw-bold">
    <span class="pe-2 icon-arrow-1-left text-white fw-bold"></span>
    <span class="fw-bold text-white font-size-14">Back to Affiliates</span>
  </a>
</div>

<div class="container" *ngIf="company$ | async as company">
  <div class="company-image d-flex justify-content-center align-items-center">
    <img src="{{ company.ImageUrl | contentImage: '1' }}" alt="{{ company.ImageUrl }}" />
  </div>
  <h5 class="text-center text-capitalize fw-bold mb-3">Offer Available</h5>
  <fgb-affiliate-item [department]="(departments$ | async)[0]"></fgb-affiliate-item>
  <div class="d-none d-lg-block">
    <a [routerLink]="[backButtonLink]" class="btn mt-3 d-flex align-items-center h-100 fw-bold">
      <span class="pe-2 icon-arrow-1-left text-primary fw-bold"></span>
      <span class="fw-bold text-primary font-size-14">Back to Affiliates</span>
    </a>
  </div>
</div>
