<!--Banner-->
<div class="banner"></div>

<div class="container">
  <!-- Cards -->
  <div class="row fixed-height-cards mt-3">
    <div class="col-12 d-flex d-lg-none">
      <fgb-barcode [showMobilePass]="false" [cardToken]="defaultCardToken$ | async"></fgb-barcode>
    </div>
    <div class="col-6 d-none d-lg-block">
      <div class="redeem-points-card bg-primary text-white rounded shadow p-3">
        <span class="material-icons"> card_giftcard </span>
        <div class="content">
          <h5 class="text-capitalize mt-2 font-size-16 fw-bold text-end">{{ 'home.widget.redeem.title' | translate }}</h5>
          <a [routerLink]="['/rewards']">
            <div class="find-out-more bg-white rounded mx-auto mt-3">
              <h5 class="font-size-14 fw-bold text-primary">{{ 'home.widget.redeem.desc' | translate }}</h5>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="col-6 d-none d-lg-block">
      <div class="redeem-points-card bg-primary text-white rounded shadow p-3">
        <span class="material-icons"> loyalty </span>
        <div class="content">
          <h5 class="text-capitalize mt-2 font-size-16 fw-bold text-end">{{ 'home.widget.offers.title' | translate }}</h5>
          <a [routerLink]="['/offers']">
            <div class="find-out-more bg-white rounded mx-auto mt-3">
              <h5 class="font-size-14 fw-bold text-primary">{{ 'home.widget.offers.desc' | translate }}</h5>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Video -->
<div [ngClass]="{ 'mt-lg-4 mt-3 video': hasVideo }">
  <fgb-worksheet-list (hasVideo)="hasVideo = $event" [isList]="false"></fgb-worksheet-list>
</div>

<!--Featured Rewards-->
<div class="container">
  <fgbcl-featured-rewards [showAllPath]="'/rewards'"></fgbcl-featured-rewards>
</div>

<!-- Announcements -->
<div class="container">
  <div class="row">
    <div class="col-12">
      <fgb-announcements></fgb-announcements>
    </div>
  </div>
</div>
