<form [formGroup]="addForm" class="row" (ngSubmit)="submit()">
  <div class="col-lg-3">
    <label class="ps-2">Partner Full Name</label>
    <input formControlName="fullName" placeholder="Full Name Here" class="form-control bg-muted border-0 ps-2" />
  </div>
  <div class="offset-lg-1 col-lg-3">
    <label class="ps-2 mt-4 mt-lg-0">Partner Email</label>
    <input formControlName="email" placeholder="username@email.com" type="email" class="form-control bg-muted border-0 ps-2" />
  </div>
  <div class="col-lg-4 col-10 d-flex align-items-end mt-4 mt-lg-0">
    <button
      type="submit"
      [disabled]="addForm.invalid"
      class="btn btn-block btn-success flex-grow-0 fw-bold me-0 ms-auto"
    >
      Add New Partner
    </button>
  </div>
  <div class="col-lg-1 col-2 d-flex align-items-center justify-content-center mt-4">
    <div (click)="close.emit(); clearErrorsOnClose()" class="icon icon-cancel-circle text-primary"></div>
  </div>
</form>
