import { OnInit, Input, OnChanges, SimpleChanges, Component } from '@angular/core';
import { TransactionType, Transaction, TransactionService, TransactionQuery } from '@fgb/core';
import * as moment from 'moment';
import { switchMap, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ViewportScroller } from '@angular/common';

interface GroupedTransactions {
  [day: string]: Transaction[];
}

@Component({
  selector: 'fgb-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.scss'],
})
export class TransactionListComponent implements OnInit, OnChanges {
  constructor(private transactionService: TransactionService, private transactionQuery: TransactionQuery,
    private scroller: ViewportScroller) {}
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() memberId: string;
  @Input() transactionType: TransactionType;
  /** moment date format */
  @Input() dateFormat: string = 'MMM DD, YYYY';
  @Input() ecashView: boolean;
  @Input() accountView: boolean;

  transactions$: Observable<GroupedTransactions>;
  transactionTypes = TransactionType;
  keys = Object.keys;
  loading: boolean = false;
  hasTransactions = false;

  ngOnInit() {
    this.getTransactions();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.startDate || changes.endDate || changes.memberId || changes.transactionType) {
      this.getTransactions();
    }
  }

  getTransactions() {
    this.loading = true;
    this.transactions$ = this.transactionService
      .fetchTransactions(this.startDate, this.endDate, this.memberId, this.transactionType)
      .pipe(
        switchMap(() => this.transactionQuery.selectTransactions(this.startDate, this.endDate, this.transactionType)),
        map((t: Transaction[]) => {
          return this._groupByTransactionDate(t);
        }),
        tap((groupedTransactions) => {
          this.hasTransactions = Object.keys(groupedTransactions).length > 0;
          this.loading = false;
        })
      );
      this.scroller.scrollToAnchor('transactionList');
  }

  private _groupByTransactionDate(transactions: Transaction[]): GroupedTransactions {
    // sort by date descending
    const sorted = transactions.sort(
      (a, b) => new Date(b.TransactionDateUTC).getTime() - new Date(a.TransactionDateUTC).getTime()
    );

    // group into object with stringified dates as keys
    return sorted.reduce<GroupedTransactions>((grouped, tx) => {
      const txDate = moment(tx.TransactionDateUTC).format(this.dateFormat);
      if (grouped[txDate]) {
        grouped[txDate].push(tx);
      } else {
        grouped[txDate] = [tx];
      }
      return grouped;
    }, {});
  }

  keepOrder = (a: any, b: any) => a.key;
}
