<div class="container d-flex align-items-center">
  <div class="card mx-auto">
    <div class="card-body text-center py-4">
      <div class="text-danger d-flex align-items-center justify-content-center">
        <span class="material-icons text-danger me-2"> add_card </span>
        <label class="title pt-1 m-0 font-size-16">{{ 'ecash.failure.title' | translate }}</label>
      </div>
      <hr class="text-info" />
      <div class="my-4 sub-heading-modal">
        <div class="font-size-14">
          {{ 'ecash.failure.description' | translate }}
        </div>
      </div>
      <div class="btn-container mt-3 d-block">
        <button [routerLink]="['/ecash']" type="button" class="btn btn-danger rounded text-white mx-2 mt-4 btn-text fw-bold">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
