import { Component, OnInit, Input } from '@angular/core';
import { AnnouncementModel } from '@fgb/core';
import { DateTime } from 'luxon';
interface AnnouncementModelExtend extends AnnouncementModel {
  LinkTitle: string;
}
@Component({
  selector: 'fgb-announcement-content',
  templateUrl: './announcement-content.component.html',
  styleUrls: ['./announcement-content.component.scss'],
})
export class AnnouncementContentComponent implements OnInit {
  @Input() announcement: AnnouncementModelExtend;
  isComingSoon: boolean = false;

  constructor() {}

  ngOnInit() {
    const comingSoonDate = DateTime.fromISO(this.announcement.ComingSoonDate as string, { zone: 'utc' });
    const requestTime = DateTime.fromISO(this.announcement.RequestTime as string, { zone: 'utc' });
    const itemStartDate = DateTime.fromISO(this.announcement.StartDate as string, { zone: 'utc' });

    this.isComingSoon = this.announcement.AllowComingSoon && (itemStartDate > requestTime || comingSoonDate > requestTime);
  }
}
