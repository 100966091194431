<div class="d-block d-lg-none">
  <div class="container py-3">
    <fgb-barcode [jetsCashDesign]="true" [cardToken]="defaultCardToken$ | async" [showMobilePass]="false"></fgb-barcode>
  </div>
</div>

<div class="d-none d-lg-block">
  <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
</div>
<ng-container *ngIf="walletItemsUnclaimed$ | async as walletItemsUnclaimed; else noWalletItems">
  <div class="wallet-redeem-carousel pt-lg-2">
    <div class="container">
      <ng-container *ngIf="walletItemsUnclaimed && walletItemsUnclaimed.length; else noWalletItems">
        <fgbcl-rewards-wallet-redeem-list [unRedeemedItems]="walletItemsUnclaimed"></fgbcl-rewards-wallet-redeem-list>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #noWalletItems>
  <div class="container">
    <h5 class="no-wallet-items mt-3 mb-3 fw-bold">{{ 'rewards.wallet.no.items' | translate }}</h5>
    <div class="border">
      <div class="no-wallet-items-description fw-bold font-size-12 text-center text-grey py-3">
        {{ 'rewards.wallet.no.items.description' | translate }}
      </div>
    </div>
  </div>
</ng-template>

<div class="container mt-3 text-center">
  <a class="font-size-14 claimed-text fw-bold" [routerLink]="['/rewards/wallet/claimed']"> {{ 'wallet.view' | translate }} </a>
</div>
