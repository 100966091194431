<div class="survey-container overflow-hidden rounded quiz">
  <div class="survey-header">
    <div class="image-container">
      <img src="{{ survey.ImageURL | contentImage: '1' }}" alt="{{ survey.ImageURL }}" />
    </div>
    <div class="survey-info text-white bg-quiz">
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <span class="svg svg-white d-inline-block my-0" [inlineSVG]="'assets/images/icons/nav-quiz.svg'"> </span>
          <span class="ps-2 fw-bold">QUIZ</span>
        </div>
        <div class="text-end" *ngIf="!survey.HasBeenAnsweredByUser">
          <div class="font-size-12">Time left to play</div>
          <fgb-countdown
            [endDateTime]="survey.EndDate"
            [requestDateTime]="survey.RequestTime"
            [isUTC]="false"
            class="font-size-16 fw-bold"
          ></fgb-countdown>
        </div>
      </div>
      <div class="survey-title position-absolute font-size-16 line-height-18">
        <div>{{ survey.SurveyQuestion }}</div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="survey.ComingSoon">
    <p class="text-center">Coming soon</p>
  </ng-container>

  <ng-container *ngIf="!survey.ComingSoon">
    <ng-container *ngIf="survey.HasBeenAnsweredByUser; else answer">
      <fgb-survey-item-answer-completed-quiz [survey]="survey"></fgb-survey-item-answer-completed-quiz>
    </ng-container>
    <ng-template #answer>
      <fgb-survey-item-answer [survey]="survey"></fgb-survey-item-answer>
    </ng-template>
  </ng-container>
</div>
