<ng-container *ngIf="zoomConference$ | async as zoomConference; else loadingRef">
    <ng-container *ngIf="validItem">
      <fgb-zoom-conference meetingPassword="{{zoomConference.MeetingPassword}}" meetingNumber="{{zoomConference.MeetingNumber}}" apiKey="{{zoomConference.MeetingKey}}" signature="{{token}}"></fgb-zoom-conference>
   </ng-container>
    <ng-container *ngIf="!validItem">
        <p>You are not allowed to join this call</p>
    </ng-container>
</ng-container>

  <!-- Loading -->
  <ng-template #loadingRef>
    <div class="w-100">
      <fgb-loading></fgb-loading>
    </div>
  </ng-template>
