<div class="survey-answer">
  <div class="text-center points-won fw-bold text-tertiary pb-3 font-size-16 d-none d-lg-block" *ngIf="pointsWon > 0">
    +{{ pointsWon }} Pts
  </div>
  <div class="survey-answer-container py-3 mx-lg-5">
    <div class="col-12 col-lg-6 p-0 my-2 my-lg-3" *ngFor="let answer of survey.SurveyAnswers">
      <div class="survey-answer-item w-90">
        <fgb-survey-item-answer-completed-poll-item
          [answer]="answer"
          [entryCount]="survey.SurveyEntryCount"
        ></fgb-survey-item-answer-completed-poll-item>
      </div>
    </div>
  </div>
</div>
