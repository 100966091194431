<div class="d-lg-none d-block container my-3">
  <div class="">
    <fgb-barcode
      [jetsCashDesign]="true"
      [cardToken]="defaultCardToken$ | async"
      (openAddNewCard)="openAddNewCard()"
      [showMobilePass]="true"
    ></fgb-barcode>
  </div>
</div>

<div class="d-lg-none d-block">
  <ul class="nav nav-secondary mixed-loyalty-nav">
    <a
      class="nav-item d-flex justify-content-center align-items-center py-1"
      [routerLink]="['/ecash']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <span class="material-icons"> credit_card </span>
      <label class="m-0">{{ 'nav.jet.cash' | translate }}</label>
    </a>
    <a
      class="nav-item d-flex justify-content-center align-items-center px-0 py-1"
      [routerLink]="['/ecashhistory']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <span class="material-icons"> history </span>
      <label class="m-0">{{ 'nav.activity' | translate }}</label>
    </a>
  </ul>
</div>
<div class="container mt-4">
  <div class="cards-container d-lg-none d-block">
    <fgbcl-card-token-list
      [regex]="regex"
      [provider]="provider"
      [redirectUrl]="'/cardtoken/success'"
      [failureUrl]="'/cardtoken/failure'"
    ></fgbcl-card-token-list>
  </div>

  <div class="d-lg-block d-none">
    <h5 class="text-black font-page-heading text-capitalize text-center">{{ 'ecash.page.access.card' | translate }}</h5>
    <div class="text-center font-size-14">
      {{ 'ecash.page.qr.text' | translate }}
    </div>
  </div>
  <fgbcl-card-link-earn-steps
    [cardTitle]="'payment.description.title'"
    [stepOneIcon]="'credit_card'"
    [stepTwoIcon]="'qr_code_scanner'"
    [stepThreeIcon]="'emoji_events'"
    [stepOneText]="'payment.description.step.one'"
    [stepTwoText]="'payment.description.step.two'"
    [stepThreeText]="'payment.description.step.three'"
    [horizontalLine]="true"
    [filled]="true"
    [border]="false"
  >
  </fgbcl-card-link-earn-steps>
  <div class="cards-container d-lg-block d-none">
    <div class="cards-container">
      <fgbcl-card-token-list
        [regex]="regex"
        [provider]="provider"
        [redirectUrl]="'/cardtoken/success'"
        [failureUrl]="'/cardtoken/failure'"
      ></fgbcl-card-token-list>
    </div>

    <div class="d-lg-block d-none">
      <p class="mb-2 font-page-heading text-uppercase heading-text">{{ 'transaction.history.title' | translate }}</p>
      <fgb-transaction-history [transactionTypes]="[transactionTypes.payment]" [ecashView]="true"></fgb-transaction-history>
    </div>
  </div>
</div>
