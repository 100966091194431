<div class="card b-0 mt-3 mt-lg-0 rounded">
  <div class="card-body p-3">
    <div class="row">
      <div class="col-12 col-lg-6">
        <fgbcl-date-range-picker
          [minDate]="transactionStartDate"
          [(startDate)]="transactionStartDate"
          [(endDate)]="transactionEndDate"
        ></fgbcl-date-range-picker>
      </div>
      <div class="col-12 col-lg-6">
        <button
          type="button"
          class="search-button fw-bold d-block ms-auto me-auto rounded border-0 text-white bg-primary"
          [ngClass]="{
            'w-100': jetsCash
          }"
          (click)="search()"
        >
          {{ 'activity.search.button' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="!isFirstAccess">
  <fgb-transaction-list
    [startDate]="selectedValues.start"
    [endDate]="selectedValues.end"
    [memberId]="memberId"
    [transactionType]="selectedValues.type"
  ></fgb-transaction-list>
</ng-container>
