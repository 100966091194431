<nav class="navbar bg-primary navbar-dark navbar-expand-lg d-none d-lg-flex">
  <a class="navbar-brand py-0" [routerLink]="['/']" routerLinkActive="router-link-active">
    <img class="ms-1 h-100" src="assets/images/logo.png" draggable="false" />
  </a>

  <button
    class="navbar-toggler"
    type="button"
    (click)="isCollapsed = !isCollapsed"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- Left Side Content -->
  <div class="navbar-collapse" [ngbCollapse]="isCollapsed" id="navbarSupportedContent">
    <div class="nav-container">
      <ul class="navbar-nav me-auto" #greedyNav>
        <li class="nav-item active">
          <a class="nav-link mx-1" [routerLink]="['/']" routerLinkActive="router-link-active">{{ 'nav.home' | translate }}</a>
        </li>
        <li class="nav-item active">
          <a class="nav-link mx-1" [routerLink]="['/rewards']" routerLinkActive="router-link-active">{{
            'nav.rewards' | translate
          }}</a>
        </li>
        <li class="nav-item active">
          <a class="nav-link mx-1" [routerLink]="['/offers']" routerLinkActive="router-link-active">{{
            'nav.offers' | translate
          }}</a>
        </li>
        <li class="nav-item active">
          <a class="nav-link mx-1" [routerLink]="['/ecash']" routerLinkActive="router-link-active">{{
            'nav.ecash' | translate
          }}</a>
        </li>

        <li class="nav-item active">
          <a class="nav-link mx-1" [routerLink]="['/earn']" routerLinkActive="router-link-active">{{ 'nav.earn' | translate }}</a>
        </li>
        <li ngbDropdown class="nav-item nav-help">
          <a type="button" class="nav-help-text nav-link" id="helpdropdownMenu" ngbDropdownToggle>
            {{ 'navbar.help' | translate }}
          </a>
          <div ngbDropdownMenu aria-labelledby="helpdropdownMenu" class="nav-help-links">
            <a ngbDropdownItem class="link" href="{{ 'faqs.link' | translate }}" target="_blank">{{
              'navbar.faq' | translate
            }}</a>
            <a ngbDropdownItem class="link" [routerLink]="['/contact']">{{ 'navbar.contact' | translate }}</a>
            <a ngbDropdownItem class="link" href="{{ 'terms.link' | translate }}" target="_blank">{{
              'navbar.terms' | translate
            }}</a>
          </div>
        </li>
      </ul>
    </div>
    <!-- Right Side Content -->
    <div class="d-flex align-items-center flex-nowrap my-2 my-lg-0">
      <div *inPopulation="[populations.GuardianTest]">
        <div *inProductCode="[productcode.PremiumDigitalMember]">
          <fgb-back-to-guardian></fgb-back-to-guardian>
        </div>
      </div>
      <div [routerLink]="['/activity']" class="balance font-size-14 text-primary cursor-pointer">
        <div *ngIf="loyaltyPointsCard$" class="text-white d-flex align-items-center justify-content-center">
          <span class="material-icons font-size-20 me-2"> emoji_events </span>
          <span class="fw-bold points">{{ loyaltyPointsCard$ | async | FortressCurrencyPipe : 'PTS' }}</span>
        </div>
      </div>

      <div [routerLink]="['/ecash']" class="balance font-size-14 text-primary cursor-pointer">
        <div *ngIf="combinedCard$" class="text-white d-flex align-items-center justify-content-center">
          <span class="material-icons font-size-20 me-2"> credit_card </span>
          <span class="fw-bold points">{{ combinedCard$ | async | FortressCurrencyPipe }}</span>
        </div>
      </div>

      <hr class="divider" />
      <div class="notification-button-wrapper">
        <fgb-notification-button />
      </div>
      <hr class="divider" />

      <!--New Dropdown (Needs Manage Tickets update before being looked at again)-->
      <div class="member" ngbDropdown #ddAccount="ngbDropdown" [autoClose]="'outside'">
        <button
          class="nav-link btn text-white d-flex align-items-center ms-4 me-2 px-0 justify-content-end"
          id="namedropdown"
          ngbDropdownToggle
        >
          <div *ngIf="memberDetails$ | async as memberDetails; else memberDetailsLoading" class="user-box h-100 d-inline-block">
            <div class="font-size-14 text-start text-capitalize">{{ memberDetails!.FirstName }}</div>
          </div>
        </button>

        <div
          ngbDropdownMenu
          (click)="closeDropDown(ddAccount)"
          aria-labelledby="namedropdown"
          class="shadow text-start dropdown-menu account-dropdown mt-2"
        >
          <div class="text-black">
            <fgb-manage-tickets></fgb-manage-tickets>
          </div>
          <hr class="text-muted my-0" />
          <div class="text-black">
            <a
              [routerLink]="['/rewards/wallet']"
              routerLinkActive="router-link-active"
              class="d-flex align-items-center account-btn text-black bg-white py-0 nav-item-fixed-height"
              ngbDropdownItem
            >
              <span class="material-icons me-2 fw-bold font-size-22"> account_balance_wallet </span>
              <div class="fw-bold dropdown-link text-start">
                <div class="font-size-14 font-heading">{{ 'nav.wallet' | translate }}</div>
              </div>
            </a>
          </div>
          <hr class="text-muted my-0" />

          <div>
            <button
              class="logout-btn d-flex align-items-center bg-white nav-item-fixed-height rounded-bottom"
              (click)="logout()"
              ngbDropdownItem
            >
              <span class="logout material-icons fw-bold font-size-22 me-2"> power_settings_new</span>
              <div class="dropdown-link text-start">
                <div class="fw-bold font-heading logout py-1 font-size-14" ngbDropdownItem (click)="logout()">
                  {{ 'nav.log.out' | translate }}
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<ng-template #memberDetailsLoading>
  <div class="user-box d-block text-center text-on-primary px-4">
    <fgb-loading [size]="'small'"></fgb-loading>
  </div>
</ng-template>
