<div [formGroup]="Form">
  <div class="form-row">
    <div [ngClass]="{ 'form-group col-md-6': !SeparateRow, 'col-md-12': SeparateRow }">
      <label class="mb-2" for="Country">Country</label>
      <select
        (change)="this.getRegionsByCountry($event.target.value)"
        id="Country"
        class="form-control border-0 bg-muted"
        name="Country"
        formControlName="Country"
        [ngClass]="{ 'is-invalid': HasSubmitted && !!Form.controls.Country.errors }"
        required
      >
        <option *ngFor="let country of countryOptions" [value]="country.CountryName">
          {{ country.DisplayName }}
        </option>
      </select>
      <div class="invalid-feedback">Please select a country</div>
    </div>
    <div [ngClass]="{ 'form-group col-md-6': !SeparateRow, 'col-md-12': SeparateRow }">
      <label class="mb-2" for="County">State</label>
      <select
        *ngIf="regionOptions.length > 1"
        id="County"
        class="form-control border-0 bg-muted"
        name="County"
        type="text"
        formControlName="County"
        [ngClass]="{ 'is-invalid': HasSubmitted && !!Form.controls.County.errors }"
        required
      >
        <option value="" selected disabled hidden>--select--</option>
        <option *ngFor="let region of regionOptions" value="{{ region.RegionName }}">
          {{ region.DisplayName }}
        </option>
      </select>

      <input
        *ngIf="regionOptions[0] == null"
        id="County"
        class="form-control border-0 bg-muted"
        name="County"
        type="text"
        formControlName="County"
        [ngClass]="{ 'is-invalid': HasSubmitted && !!Form.controls.County.errors }"
        required
      />
      <div *ngIf="regionOptions.length > 1" class="invalid-feedback">Please select a {{ RegionLabel.toLowerCase() }}</div>
      <div *ngIf="regionOptions[0] == null" class="invalid-feedback">Please enter a {{ RegionLabel.toLowerCase() }}</div>
    </div>
  </div>
</div>
