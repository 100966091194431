import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnnouncementsComponent } from './components/announcements/announcements.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AnnouncementContentComponent } from './components/announcement-content/announcement-content.component';
import { CompanyListComponent } from './components/company/company-list/company-list.component';
import { CompanyListItemComponent } from './components/company/company-list-item/company-list-item.component';
import { SurveyItemComponent } from './components/survey/survey-item/survey-item.component';
import { SurveyItemAnswerComponent } from './components/survey/survey-item-answer/survey-item-answer.component';
import { AffiliateItemComponent } from './components/department/affiliate-item/affiliate-item.component';
import { TransferArenaCashComponent } from './components/transfer-arena-cash/transfer-arena-cash/transfer-arena-cash.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TransferArenaMultipleAccountsComponent } from './components/transfer-arena-cash/transfer-arena-multiple-accounts/transfer-arena-multiple-accounts.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { EarnPointsCardComponent } from './components/earn-points-card/earn-points-card.component';
import { ProgressBarComponent } from './components/average-points/progress-bar/progress-bar.component';
import { SurveyItemQuizComponent } from './components/survey/survey-item/survey-item-quiz/survey-item-quiz.component';
import { SurveyItemPredictorComponent } from './components/survey/survey-item/survey-item-predictor/survey-item-predictor.component';
import { SurveyItemPollComponent } from './components/survey/survey-item/survey-item-poll/survey-item-poll.component';
import { SurveyCompletedPollComponent } from './components/survey/survey-item-answer/completed/survey-completed-poll/survey-completed-poll.component';
import { SurveyCompletedQuizComponent } from './components/survey/survey-item-answer/completed/survey-completed-quiz/survey-completed-quiz.component';
import { SurveyCompletedPollItemComponent } from './components/survey/survey-item-answer/completed/survey-completed-poll/survey-completed-poll-item/survey-completed-poll-item.component';
import { SurveyCompletedQuizTextComponent } from './components/survey/survey-item-answer/completed/survey-completed-quiz/survey-completed-quiz-text/survey-completed-quiz-text.component';
import { SurveyCompletedQuizImageAndTextComponent } from './components/survey/survey-item-answer/completed/survey-completed-quiz/survey-completed-quiz-image-and-text/survey-completed-quiz-image-and-text.component';
import { SurveyCompletedPredictorComponent } from './components/survey/survey-item-answer/completed/survey-completed-predictor/survey-completed-predictor.component';
import { PredictorCompletedPendingComponent } from './components/survey/survey-item-answer/completed/survey-completed-predictor/predictor-completed-pending/predictor-completed-pending.component';
import { PredictorCompletedProcessedComponent } from './components/survey/survey-item-answer/completed/survey-completed-predictor/predictor-completed-processed/predictor-completed-processed.component';
import { LeaderboardComponent } from './components/leaderboard/leaderboard.component';
import { RedSoxNationComponent } from './components/red-sox-nation/red-sox-nation.component';
import { RouterModule } from '@angular/router';
import { JokesCardComponent } from './components/jokes-card/jokes-card.component';
import { ProgressBarGameAttendanceComponent } from './components/progress-bar-game-attendance/progress-bar-game-attendance.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { OffersListItemComponent } from './components/offers/offers-list-item/offers-list-item.component';
import { OffersListComponent } from './components/offers/offers-list/offers-list.component';
import { OffersDetailsComponent } from './components/offers/offers-details/offers-details.component';
import { OfferTermsModalComponent } from './components/offers/offer-terms-modal/offer-terms-modal.component';
import { PartnersListItemComponent } from './components/offers/partners-list-item/partners-list-item.component';
import { ScorePredictorItemComponent } from './components/score-predictor/score-predictor-item/score-predictor-item.component';
import { ScorePredictorCompletedComponent } from './components/score-predictor/score-predictor-completed/score-predictor-completed.component';
import { ScorePredictorCompletedPendingComponent } from './components/score-predictor/score-predictor-completed/score-predictor-completed-pending/score-predictor-completed-pending.component';
import { ScorePredictorCompletedProcessedComponent } from './components/score-predictor/score-predictor-completed/score-predictor-completed-processed/score-predictor-completed-processed.component';
import { ScorePredictorRulesComponent } from './components/score-predictor/score-predictor-rules/score-predictor-rules.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FGBTranslationLoader } from '@fgb/core';
import { HttpClient } from '@angular/common/http';
import { JetsAttendanceBonusComponent } from './components/jets-attendance-bonus/jets-attendance-bonus.component';
import { CountdownV2Component } from '@fgb/portal-component-library/src/lib/shared/countdown-v2';
@NgModule({
  declarations: [
    AnnouncementsComponent,
    AnnouncementContentComponent,
    CompanyListComponent,
    CompanyListItemComponent,
    SurveyItemComponent,
    SurveyItemAnswerComponent,
    SurveyCompletedQuizComponent,
    SurveyCompletedPollComponent,
    SurveyCompletedPollItemComponent,
    SurveyCompletedPredictorComponent,
    AffiliateItemComponent,
    TransferArenaCashComponent,
    TransferArenaMultipleAccountsComponent,
    SurveyCompletedQuizTextComponent,
    SurveyCompletedQuizImageAndTextComponent,
    EarnPointsCardComponent,
    ProgressBarComponent,
    SurveyItemQuizComponent,
    SurveyItemPredictorComponent,
    SurveyItemPollComponent,
    PredictorCompletedPendingComponent,
    PredictorCompletedProcessedComponent,
    LeaderboardComponent,
    RedSoxNationComponent,
    JokesCardComponent,
    ProgressBarGameAttendanceComponent,
    OffersListItemComponent,
    OffersListComponent,
    OffersDetailsComponent,
    OfferTermsModalComponent,
    PartnersListItemComponent,
    ScorePredictorItemComponent,
    ScorePredictorCompletedComponent,
    ScorePredictorCompletedPendingComponent,
    ScorePredictorCompletedProcessedComponent,
    ScorePredictorRulesComponent,
    JetsAttendanceBonusComponent,
  ],
  imports: [
    NgbModule,
    SharedModule,
    CommonModule,
    CarouselModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    InlineSVGModule.forRoot(),
    RouterModule,
    CountdownV2Component,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: FGBTranslationLoader,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    AnnouncementsComponent,
    AnnouncementContentComponent,
    CompanyListComponent,
    CompanyListItemComponent,
    SurveyItemComponent,
    AffiliateItemComponent,
    TransferArenaCashComponent,
    TransferArenaMultipleAccountsComponent,
    EarnPointsCardComponent,
    ProgressBarComponent,
    LeaderboardComponent,
    RedSoxNationComponent,
    JokesCardComponent,
    ProgressBarGameAttendanceComponent,
    OffersListItemComponent,
    OffersListComponent,
    OffersDetailsComponent,
    OfferTermsModalComponent,
    PartnersListItemComponent,
    ScorePredictorItemComponent,
    ScorePredictorCompletedComponent,
    ScorePredictorCompletedPendingComponent,
    ScorePredictorCompletedProcessedComponent,
    ScorePredictorRulesComponent,
    JetsAttendanceBonusComponent,
  ],
})
export class ClubModule {}
