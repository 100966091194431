import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  config,
  CompanyQuery,
  Company,
  CompanyOfferModel,
  CompanyOfferQuery,
  MemberQuery,
  CardTokenQuery,
  CardToken,
} from '@fgb/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';

@Component({
  selector: 'fgb-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  config = config;
  companies$: Observable<Company[]>;
  featuredOffers$: Observable<CompanyOfferModel[]>;
  name$: Observable<string>;

  customOptions: OwlOptions = {
    autoHeight: true,
    autoWidth: true,
    margin: OwlCarouselSettings.Margin,
    autoplay: OwlCarouselSettings.Autoplay,
    autoplayTimeout: OwlCarouselSettings.AutoplayTimeout,
    autoplaySpeed: OwlCarouselSettings.AutoplaySpeed,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: { items: 1 },
      740: { items: 2 },
      900: { items: 3 },
    },
  };
  hasVideo: boolean = false;
  defaultCardToken$: Observable<CardToken>;
  constructor(
    private companyQuery: CompanyQuery,
    private memberQuery: MemberQuery,
    private companyOfferQuery: CompanyOfferQuery,
    private router: Router,
    private cardTokenQuery: CardTokenQuery
  ) {}

  ngOnInit() {
    this.featuredOffers$ = this.companyOfferQuery.selectFeaturedOffers();
    this.companies$ = this.companyQuery.selectFavouriteCompanies().pipe(
      map((companies) => {
        return companies;
      })
    );
    this.name$ = this.memberQuery.selectMemberDetails().pipe(
      map((m) => {
        if (m) {
          return `${m.FirstName} ${m.Surname}`;
        }
        return '';
      })
    );
    this.defaultCardToken$ = this.cardTokenQuery.selectDefaultCard();
  }

  navigateToOffer(offerId: number) {
    this.router.navigateByUrl(`offers/details/${offerId}`);
  }
}
