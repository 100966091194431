import { Injectable } from '@angular/core';
import { ApiService, LoggingService } from '@fgb/core';
import { Observable, of } from 'rxjs';
import { mapTo, tap } from 'rxjs/operators';
import { MobilePass } from './mobile-pass.model';
import { MobilePassQuery } from './mobile-pass.query';
import { MobilePassStore } from './mobile-pass.store';

@Injectable({ providedIn: 'root' })
export class MobilePassService {
  constructor(
    private mobilePassStore: MobilePassStore,
    private api: ApiService,
    private mobilePassQuery: MobilePassQuery,
    private loggingService: LoggingService
  ) {}

  /**
   * This will fetch the mobile pass URL.
   * @param memberId Member ID of current user
   * @returns Request to fetch mobile pass URL if this
   * does not exist within the mobile pass store.
   */
  fetchPass(memberId: string) {
    let url = memberId ? `passes?memberId=${memberId}` : 'passes';
    this.loggingService.info('Fetching pass URL for current user...');

    const request$: Observable<null> = this.api.getAnySingle<any>(url).pipe(
      tap((passUrlResonse) => {
        this.loggingService.info('Pass URL found.', passUrlResonse);
        let passUrl: MobilePass = { url: passUrlResonse };
        this.mobilePassStore.set([passUrl]);
      }),
      mapTo(null)
    );

    return this.mobilePassQuery.getHasCache() ? of(null) : request$;
  }
}
