import { Component, OnInit } from '@angular/core';
import { Game, GameService, GameDataSource, GameQuery } from '@fgb/core';
import { firstValueFrom, Observable } from 'rxjs';

@Component({
  selector: 'fgb-next-game',
  templateUrl: './next-game.component.html',
  styleUrls: ['./next-game.component.scss'],
})
export class EventsComponent implements OnInit {
  nextGame$: Observable<Game | null>;

  constructor(private gameService: GameService, private gameQuery: GameQuery) {}

  ngOnInit() {
    firstValueFrom(this.gameService.fetchGames('', GameDataSource.Club));
    this.nextGame$ = this.gameQuery.selectNextGame(GameDataSource.Club);
  }
}
