<div class="container-fluid">
  <div class="row d-lg-none text-center">
    <div class="col-6 bg-primary text-on-secondary py-1">
      <div class="d-flex justify-content-center align-items-center h-100">
        <span class="icon icon-trophy-1"></span>
        <span class="fw-bold ps-1">{{ points$ | async | FortressCurrencyPipe: 'PTS' }}</span>
      </div>
    </div>
    <div class="col-6 bg-success text-on-success py-1">
      <div class="d-flex justify-content-center align-items-center h-100">
        <span class="icon icon-money-bag-coins"></span>
        <span class="fw-bold ps-1">{{ eCash$ | async | FortressCurrencyPipe }}</span>
      </div>
    </div>
  </div>
</div>
