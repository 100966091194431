import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { MobilePass } from './mobile-pass.model';

export interface MobilePassState extends EntityState<MobilePass> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'mobile-pass' })
export class MobilePassStore extends EntityStore<MobilePassState> {

  constructor() {
    super();
  }

}
