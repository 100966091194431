import { Component, OnInit, Input } from '@angular/core';
import { ProcessProviderId } from '@fgb/core';

@Component({
  selector: 'fgb-loyalty-icon',
  templateUrl: './loyalty-icon.component.html',
  styleUrls: ['./loyalty-icon.component.scss'],
})
export class LoyaltyIconComponent implements OnInit {
  @Input() loyaltyType: 'auction' | 'lotto' | 'wallet' | 'walletRedeemed' | ProcessProviderId;
  icon: string;

  private iconAuction = 'icomoon icon-auction-2';
  private iconLotto = 'icomoon icon-sweepstakes';
  private iconMarketplace = 'icon icon-gift';
  private iconWallet = 'icomoon icon-redemption-history';
  private iconWalletRedeemed = 'icomoon icon-redemption-history';
  private iconMarketplaceEvent = 'icomoon icon-event';

  constructor() {}

  ngOnInit() {
    switch (this.loyaltyType) {
      case 'auction':
        this.icon = this.iconAuction;
        break;
      case 'lotto':
        this.icon = this.iconLotto;
        break;
      case 'wallet':
        this.icon = this.iconWallet;
        break;
      case 'walletRedeemed':
        this.icon = this.iconWalletRedeemed;
        break;
      case ProcessProviderId.Event:
        this.icon = this.iconMarketplaceEvent;
        break;
      default:
        this.icon = this.iconMarketplace;
        break;
    }
  }
}
