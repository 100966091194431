import { Component, OnInit } from '@angular/core';
import { PartnerQuery, Partner, PartnerService } from '@fgb/core';
import { firstValueFrom, Observable } from 'rxjs';

@Component({
  selector: 'fgb-partner-list',
  templateUrl: './partner-list.component.html',
  styleUrls: ['./partner-list.component.scss'],
})
export class PartnerListComponent implements OnInit {
  constructor(private partnerQuery: PartnerQuery, private partnerService: PartnerService) {}
  partners$: Observable<Partner[]>;
  ngOnInit() {
    this.partners$ = this.partnerQuery.selectAll();
  }

  delete(id: number) {
    firstValueFrom(this.partnerService.remove(id));
  }
}
