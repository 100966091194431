import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  Barcode,
  BarcodeQuery,
  MemberCardQuery,
  MemberDetails,
  MemberQuery,
  SeatingDetailQuery,
  SeatingDetail,
  CardToken,
} from '@fgb/core';
import { BarcodeService } from '@fgb/core';
import { firstValueFrom, Observable } from 'rxjs';
import { MemberCard } from '@fgb/core';
import { config } from '@fgb/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BalanceQuery } from '@fgb/core';

@Component({
  selector: 'fgb-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss'],
})
export class BarcodeComponent implements OnInit {
  @Input() memberId: string;
  @Input() jetsCashDesign: boolean;
  @Input() cardToken?: CardToken;
  @Input() showMobilePass?: boolean;

  @Output() openAddNewCard = new EventEmitter<null>();

  barcode$: Observable<Barcode>;
  pointsBalance$: Observable<number | undefined>;
  ecashBalance$: Observable<number | undefined>;
  card$: Observable<MemberCard | undefined>;
  member$: Observable<MemberDetails | undefined>;
  seatingDetail$: Observable<SeatingDetail | undefined>;

  constructor(
    private barcodeService: BarcodeService,
    private barcodeQuery: BarcodeQuery,
    private balanceQuery: BalanceQuery,
    private memberCardQuery: MemberCardQuery,
    private memberDetailsQuery: MemberQuery,
    private modalService: NgbModal,
    private seatingDetailQuery: SeatingDetailQuery
  ) {}

  ngOnInit() {
    firstValueFrom(this.barcodeService.fetchBarcode());
    this.barcode$ = this.barcodeQuery.select();
    this.seatingDetail$ = this.seatingDetailQuery.selectCurrentSeatingDetails();
    this.member$ = this.memberDetailsQuery.selectMemberDetails();
    this.pointsBalance$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
    this.ecashBalance$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
    this.card$ = this.memberCardQuery.selectCurrentCard();
  }

  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  close() {
    this.modalService.dismissAll();
  }

  get isCardFozen(): boolean | undefined {
    return this.cardToken?.Frozen;
  }
}
