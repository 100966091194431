<fgb-account-nav></fgb-account-nav>

<div class="container">
  <h5 class="sub-heading font-page-heading text-black fw-bold px-2 pb-0">
    {{ 'earn.page.intro.heading' | translate }}
  </h5>
  <div class="px-2 text-primary">
    {{ 'earn.page.intro.description' | translate }}
  </div>
</div>

<div class="container">
  <fgbcl-earn-points [svgColor]="'primary'"></fgbcl-earn-points>
</div>
